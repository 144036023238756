import firebase from "~/utils/firebase";

import { BaseDocument } from "./document";

interface AdminData {
  email: string;
  uid?: string;

  createTime: firebase.firestore.Timestamp;
  updateTime: firebase.firestore.Timestamp;
}

const dataConverter: firebase.firestore.FirestoreDataConverter<AdminData> = {
  toFirestore(admin: AdminData) {
    const { email, uid, createTime, updateTime } = admin;

    return { email, uid, createTime, updateTime };
  },

  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ) {
    const data = snapshot.data(options);
    const { email, uid, createTime, updateTime } = {
      ...{
        email: "",
        createTime: new firebase.firestore.Timestamp(0, 0),
        updateTime: new firebase.firestore.Timestamp(0, 0),
      },
      ...(data as Partial<AdminData>),
    };

    return { email, uid, createTime, updateTime };
  },
};

export class AdminDoc extends BaseDocument<AdminData> {
  static collection(): firebase.firestore.CollectionReference<AdminData> {
    return firebase
      .firestore()
      .collection("admins")
      .withConverter(dataConverter);
  }

  static async addAdmin(email: string): Promise<string> {
    return this.add(this.collection(), { email });
  }

  static listenAdmins(): AdminDoc[] {
    const query = this.collection().orderBy("updateTime", "desc");
    return this.listenDocuments(query);
  }
}
