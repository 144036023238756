


































































































import Vue from "vue";

import FileUpload from "~/components/FileUpload.vue";
import FolderBreadcrumb from "~/components/FolderBreadcrumb.vue";
import FolderCreate from "~/components/FolderCreate.vue";
import FolderDelete from "~/components/FolderDelete.vue";
import FolderMove from "~/components/FolderMove.vue";
import FolderRow from "~/components/FolderRow.vue";
import RoomCreate from "~/components/RoomCreate.vue";
import RoomDelete from "~/components/RoomDelete.vue";
import RoomRow from "~/components/RoomRow.vue";

import { FolderDoc, OrganizationDoc, RoomDoc } from "~/db";
import { durationHMM } from "~/filters/time";
import auth from "~/utils/auth";
import Sortable from "~/utils/sortable";

type SortType = "name" | "timestamp" | "amount";

export default Vue.extend({
  name: "Folder",

  components: {
    FileUpload,
    FolderBreadcrumb,
    FolderCreate,
    FolderDelete,
    FolderMove,
    FolderRow,
    RoomCreate,
    RoomDelete,
    RoomRow,
  },

  filters: {
    durationHMM,
  },

  props: {
    folderId: {
      type: String as () => string | null,
      default: null,
    },
    oid: {
      type: String as () => string | undefined,
      default: undefined,
    },
  },

  data() {
    return new (class Data {
      amount = 0;
      organization = undefined as OrganizationDoc | undefined;
      allFolders: FolderDoc[] = [];
      rooms: RoomDoc[] = [];
      sortable = new Sortable<SortType>("timestamp", -1);
    })();
  },

  computed: {
    breadcrumb(): (FolderDoc | null)[] {
      const breadcrumb: (FolderDoc | null)[] = [];
      var id = this.folderId;

      while (id != null) {
        const folder = this.allFolders.find((folder) => {
          return folder.id === id;
        });

        if (folder == null) break;

        breadcrumb.unshift(folder);
        id = folder.data.parent;
      }

      breadcrumb.unshift(null);

      return breadcrumb;
    },

    folders(): FolderDoc[] {
      return this.allFolders
        .filter((folder) => {
          return folder.data.parent === this.folderId;
        })
        .sort((a, b) => {
          let result = 0;

          switch (this.sortable.type) {
            case "name":
              result = a.data.name.localeCompare(b.data.name);
              break;
            case "timestamp":
              result =
                (a.data.createTime?.toMillis() ?? Number.MAX_SAFE_INTEGER) -
                (b.data.createTime?.toMillis() ?? Number.MAX_SAFE_INTEGER);
              break;
            case "amount":
              return a.data.name.localeCompare(b.data.name);
          }

          return result * this.sortable.order;
        });
    },

    sortedRooms(): RoomDoc[] {
      return this.rooms.slice().sort((a, b) => {
        let result = 0;

        switch (this.sortable.type) {
          case "name":
            result = a.data.name.localeCompare(b.data.name);
            break;
          case "timestamp":
            result =
              (a.data.createTime?.toMillis() ?? Number.MAX_SAFE_INTEGER) -
              (b.data.createTime?.toMillis() ?? Number.MAX_SAFE_INTEGER);
            break;
          case "amount":
            result =
              (a.data.endTime?.seconds ?? 0) -
              (a.data.startTime?.seconds ?? 0) +
              (a.data.amount ?? 0) -
              (b.data.endTime?.seconds ?? 0) +
              (b.data.startTime?.seconds ?? 0) -
              (b.data.amount ?? 0);
            break;
        }

        return result * this.sortable.order;
      });
    },

    empty(): boolean {
      return this.folders.length + this.rooms.length === 0;
    },
  },

  watch: {
    folderId() {
      this.listenRooms();
    },
  },

  async created() {
    let oid: string | undefined;

    if (this.folderId == null) {
      oid = this.oid ?? auth.oid;
    } else {
      const folder = await FolderDoc.getFolder(this.folderId);
      oid = folder?.data.oid;
    }

    if (oid == null) return;

    this.allFolders = FolderDoc.listenFolders({
      oid,
      owner: auth.owner,
      uid: auth.uid,
      gid: auth.gid,
    });

    this.organization = await OrganizationDoc.listenOrganization(oid);
    this.listenRooms();
  },

  beforeDestroy() {
    OrganizationDoc.unsubscribe(this.organization);
    FolderDoc.unsubscribe(this.allFolders);
    RoomDoc.unsubscribe(this.rooms);
  },

  methods: {
    listenRooms() {
      RoomDoc.unsubscribe(this.rooms);

      if (this.organization == null) {
        this.rooms = [];
        return;
      }

      this.rooms = RoomDoc.listenRooms({
        parent: this.folderId,
        oid: this.organization.id,
        owner: auth.owner,
        uid: auth.uid,
        gid: auth.gid,
      });
    },

    async createFolder() {
      const create = this.$refs.createFolder as InstanceType<
        typeof FolderCreate
      >;
      create.open();
    },

    deleteFolder(folder: FolderDoc) {
      const del = this.$refs.deleteFolder as InstanceType<typeof FolderDelete>;
      del.open(folder);
    },

    async createRoom(microphone: boolean) {
      const oid = this.organization?.id;

      if (oid == null || auth.uid == null) return;

      if (microphone) {
        const roomId = await RoomDoc.addRoom({
          name: "無題",
          parent: this.folderId,
          oid,
          uid: auth.uid,
          type: "microphone",
        });

        this.$router.push({
          name: "room",
          params: { roomId },
          hash: "#new",
        });
      } else {
        const upload = this.$refs.fileUpload as InstanceType<typeof FileUpload>;
        upload.open();
      }
    },

    move(item: FolderDoc | RoomDoc) {
      const move = this.$refs.move as InstanceType<typeof FolderMove>;
      move.open([item]);
    },

    deleteRoom(room: RoomDoc) {
      const del = this.$refs.deleteRoom as InstanceType<typeof RoomDelete>;
      del.open(room);
    },
  },
});
