
























import Vue from "vue";
import { mapState } from "vuex";

import ModalDialog from "~/components/ModalDialog.vue";
import { createUsers } from "~/db/user";

export default Vue.extend({
  components: { ModalDialog },

  props: {
    oid: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      email: "",
      name: "",
      loading: false,
      error: undefined as Error | undefined,
    };
  },

  computed: {
    ...mapState("user", { from: "name" }),
  },

  methods: {
    dialog(): InstanceType<typeof ModalDialog> {
      return this.$refs.dialog as InstanceType<typeof ModalDialog>;
    },

    open(): void {
      this.dialog().open();
    },

    close(): void {
      this.dialog().close();
    },

    async create() {
      this.error = undefined;
      this.loading = true;

      const result = await createUsers({
        params: [{ email: this.email, name: this.name }],
        oid: this.oid,
        from: this.from,
      }).catch((error) => [{error}]);

      this.loading = false;
      this.error = result[0].error;

      if (this.error == null) {
        this.close();
      }
    },

    closed(): void {
      this.email = "";
      this.name = "";
      this.loading = false;
      this.error = undefined;
    },
  },
});
