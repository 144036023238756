


























import Vue from "vue";

import Modal from "~/components/Modal.vue";
import { UserDoc } from "~/db/user";
import firebase from "~/utils/firebase";

export default Vue.extend({
  components: { Modal },

  data() {
    return {
      user: undefined as UserDoc | undefined,
      loading: false,
      error: undefined as firebase.auth.AuthError | undefined,
    };
  },

  methods: {
    modal(): InstanceType<typeof Modal> {
      return this.$refs.modal as InstanceType<typeof Modal>;
    },

    open(user: UserDoc): void {
      this.user = user;
      this.modal().open();
    },

    close(): void {
      this.modal().close();
    },

    async delete_(): Promise<void> {
      if (this.user == null) return;

      this.loading = true;

      await this.user.remove();

      this.close();
    },

    closed(): void {
      this.user = undefined;
      this.loading = false;
      this.error = undefined;
    },
  },
});
