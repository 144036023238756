type Order = 1 | -1;

export default class Sortable<T> {
  constructor(public type: T, public order: Order = 1) {}

  sort(type: T): void {
    if (this.type === type) {
      this.order *= -1;
    } else {
      this.type = type;
    }
  }

  class(type: T): string | null {
    if (type !== this.type) return null;
    const order = this.order === 1 ? "ascending" : "descending";
    return `sorted ${order}`;
  }
}
