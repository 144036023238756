


































import Vue, { PropType } from "vue";

import FolderProtect from "~/components/FolderProtect.vue";
import { localeDateTime } from "~/filters/time";
import { FolderDoc } from "~/db";

export default Vue.extend({
  components: { FolderProtect },

  props: {
    folder: {
      type: Object as PropType<FolderDoc>,
      required: true,
    },
  },

  computed: {
    createTime(): string {
      return localeDateTime(this.folder.data.createTime);
    },
  },

  methods: {
    protect() {
      const protect = this.$refs.protect as InstanceType<typeof FolderProtect>;
      protect.open(this.folder);
    },

    move() {
      this.$emit("move", this.folder);
    },

    delete() {
      this.$emit("delete", this.folder);
    },
  },
});
