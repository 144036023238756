

























import Vue from "vue";

import AdminCreate from "~/components/AdminCreate.vue";
import AdminRow from "~/components/AdminRow.vue";
import { AdminDoc } from "~/db";

export default Vue.extend({
  name: "Admins",

  components: { AdminCreate, AdminRow },

  data() {
    return {
      admins: AdminDoc.listenAdmins(),
    };
  },

  methods: {
    create(): void {
      const create = this.$refs.create as InstanceType<typeof AdminCreate>;
      create.open();
    },
  },
});
