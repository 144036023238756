










import Vue from "vue";

export default Vue.extend({
  props: {
    text: {
      type: String,
      required: true,
    },
    pronunciation: {
      type: String,
      default: "",
    },
  },

  computed: {
    kanji(): string {
      return this.text.slice(0, -this.kana.length || undefined);
    },

    ruby(): string {
      if (this.pronunciation === "_") return "";

      return this.pronunciation.slice(0, -this.kana.length || undefined);
    },

    kana(): string {
      if (this.pronunciation === "_") return "";

      const text = [...this.text].reverse();
      const pronunciation = [...this.pronunciation].reverse();
      let i = 0;

      while (
        i < Math.min(text.length, pronunciation.length) &&
        text[i] === pronunciation[i]
      ) {
        i++;
      }

      return this.text.slice(this.text.length - i);
    },
  },
});
