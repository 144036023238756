import Vue from "vue";
import Vuex from "vuex";

import * as User from "./modules/user";
import { plugin as authPlugin } from "./plugins/auth";

Vue.use(Vuex);

interface State {
  recordingType: string;
}

const store = new Vuex.Store({
  state: {
    recordingType: localStorage.getItem("recordingType") || "mic",
  } as State & { user: User.State },

  getters: {
    microphoneRecording: (state) => {
      return state.recordingType === "mic";
    },
  },

  mutations: {
    setMicrophoneRecording: (state, microphone: boolean) => {
      state.recordingType = microphone ? "mic" : "file";
      localStorage.setItem("recordingType", state.recordingType);
    },
  },

  actions: {},
  modules: { user: User },
  plugins: [authPlugin],
});

export default store;
