





























































import Vue from "vue";

import Dropdown from "~/components/Dropdown.vue";
import Modal from "~/components/Modal.vue";

import * as db from "~/db";
import { FolderDoc } from "~/db";

export default Vue.extend({
  components: {
    Dropdown,
    Modal,
  },

  data() {
    return {
      loading: false,
      error: undefined as Error | undefined,
      folder: undefined as FolderDoc | undefined,
      groups: [] as db.Group[],
      users: [] as db.Profile[],
    };
  },

  computed: {
    formClass(): unknown {
      return {
        loading: this.loading,
        error: this.error != null,
      };
    },

    grantees(): string | undefined {
      return this.folder?.data.grantees?.join();
    },
  },

  watch: {
    folder() {
      if (this.folder == null) return;

      const grantees = this.$refs.grantees as InstanceType<typeof Dropdown>;

      grantees.setSelected(this.folder.data.grantees);
    },
  },

  created() {
    this.groups = db.getGroups();
    this.users = db.getProfiles();
  },

  methods: {
    modal(): InstanceType<typeof Modal> {
      return this.$refs.modal as InstanceType<typeof Modal>;
    },

    async open(folder: FolderDoc) {
      this.modal().open();

      await this.$nextTick(); // insert dom
      await this.$nextTick(); // enter transition

      this.folder = folder;
    },

    close(): void {
      this.modal().close();
    },

    grant(): void {
      if (this.folder != null) {
        const dropdown = this.$refs.grantees as InstanceType<typeof Dropdown>;
        const grantees = dropdown.getValue().split(",");

        this.folder.update({ grantees });
      }

      this.close();
    },

    closed(): void {
      this.loading = false;
      this.error = undefined;
      this.folder = undefined;
    },
  },
});
