import Vue from "vue";

class State {
  private readonly state = Vue.observable({
    portal: "destination",
  });

  get portal() {
    return this.state.portal;
  }

  set portal(portal: string) {
    this.state.portal = portal || "destination";
  }
}

export default new State();
