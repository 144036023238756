























































































import Vue from "vue";

import Radio from "~/components/Radio.vue";

export default Vue.extend({
  components: { Radio },

  props: {
    close: {
      type: Function,
      default: undefined,
    },
  },

  data() {
    return {
      currentTabPath: "word",
      speakerName: "display",
      outputRange: "allRow",
      sub: "subrip",
    };
  },

  mounted() {
    $(this.$el)
      .find(".menu .item")
      .tab({
        onLoad: (tabPath) => {
          this.currentTabPath = tabPath;
        },
      });
  },

  methods: {
    getCurrentTab(): string {
      return this.currentTabPath;
    },
  },
});
