




































import Vue, { PropType } from "vue";

import TextField from "~/components/TextField.vue";
import { UserDoc } from "~/db/user";
import { localeDateTime } from "~/filters/time";

export default Vue.extend({
  components: {
    TextField,
  },

  props: {
    user: {
      type: Object as PropType<UserDoc>,
      required: true,
    },
  },

  data() {
    return { editable: false };
  },

  computed: {
    createTime() {
      return localeDateTime(this.user.data.createTime);
    },

    lastSignInTime() {
      return localeDateTime(this.user.data.lastSignInTime);
    },
  },

  methods: {
    async edit() {
      this.editable = true;
      await this.$nextTick();
      (this.$refs.name as InstanceType<typeof TextField>).focus();
    },

    async onNameChange(name: string) {
      await this.user.update({ name });
    },

    onNameEnd() {
      this.editable = false;
    },

    assign(): void {
      this.user.owner(!this.user.data.owner);
    },

    delete(): void {
      this.$emit("delete", this.user);
    },
  },
});
