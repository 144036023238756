


















import Vue, { PropType } from "vue";

import { PhraseDoc } from "~/db";
import { localeDateTime } from "~/filters/time";

export default Vue.extend({
  props: {
    phrase: {
      type: Object as PropType<PhraseDoc>,
      required: true,
    },
  },

  computed: {
    createTime(): string {
      return localeDateTime(this.phrase.data.createTime);
    },
  },

  methods: {
    edit() {
      this.$emit("edit", this.phrase);
    },

    delete_() {
      this.$emit("delete", this.phrase);
    },
  },
});
