












import Vue from "vue";

export default Vue.extend({
  props: {
    accept: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    keypress(event: KeyboardEvent) {
      if (event.key === " " || event.key === "Enter") {
        event.preventDefault();
        (event.target as HTMLElement).click();
      }
    },

    change(event: Event) {
      this.$emit("change", event);
    },
  },
});
