import Vue from "vue";

export default Vue.extend({
  methods: {
    $addEventListener(
      target: EventTarget,
      type: string,
      listener: EventListenerOrEventListenerObject | null,
      options?: boolean | AddEventListenerOptions
    ) {
      target.addEventListener(type, listener, options);

      this.$once("hook:beforeDestroy", () => {
        target.removeEventListener(type, listener, options);
      });
    },
  },
});
