






































































import Vue from "vue";
import { mapState, mapGetters } from "vuex";

import Dropdown from "~/components/Dropdown.vue";
import auth from "~/utils/auth";

export default Vue.extend({
  components: { Dropdown },

  computed: {
    menu(): boolean {
      return this.signedIn && !this.anonymous;
    },

    ...mapState("user", ["name"]),
    ...mapGetters("user", ["signedIn", "anonymous", "admin", "owner"]),
  },

  methods: {
    async signOut(): Promise<void> {
      await auth.signOut();
      this.$router.push({ name: "signin" });
    },
  },
});
