







































































import Vue from "vue";

import ErrorMessage from "~/components/ErrorMessage.vue";
import FileInput from "~/components/FileInput.vue";
import Modal from "~/components/Modal.vue";
import ProgressBar from "~/components/ProgressBar.vue";
import { OrganizationDoc, RoomDoc } from "~/db";
import auth from "~/utils/auth";
import upload from "~/utils/uploader";

export default Vue.extend({
  components: {
    ErrorMessage,
    FileInput,
    Modal,
    ProgressBar,
  },

  props: {
    folderId: {
      type: String as () => string | null,
      default: null,
    },
  },

  data() {
    return {
      available: undefined as boolean | undefined,
      loading: false,
      progress: 0 as number | null,
      done: false,
      error: undefined as Error | undefined,
      roomId: undefined as string | undefined,
      name: undefined as string | undefined,
    };
  },

  computed: {
    modal() {
      return this.$refs.modal as InstanceType<typeof Modal>;
    },
  },

  methods: {
    async open() {
      this.modal.open();

      if (auth.oid == null) {
        this.available = false;
        return;
      }

      const organization = await OrganizationDoc.getOrganization(auth.oid);

      if (organization == null) {
        this.available = false;
        return;
      }

      this.available = organization.data.amount < organization.data.limit;
    },

    close() {
      this.modal.close();
    },

    create() {
      this.modal.close();
    },

    closed() {
      this.available = undefined;
      this.loading = false;
      this.progress = 0;
      this.done = false;
      this.error = undefined;
      this.roomId = undefined;
      this.name = undefined;
    },

    handleFile(event: Event) {
      const input = event.target as HTMLInputElement | null;
      const files = input?.files;
      const file = files?.[0];

      if (input != null) {
        input.value = "";
      }

      if (file != null) {
        this.upload(file);
      }
    },

    handleDrop(event: DragEvent) {
      const files = event.dataTransfer?.files;
      const file = files?.[0];

      if (file != null) {
        this.upload(file);
      }
    },

    async upload(file: File) {
      if (!this.available || this.loading) return;

      const oid = auth.oid;
      const uid = auth.uid;

      if (oid == null || uid == null) return;

      this.progress = null;
      this.loading = true;

      try {
        const name = file.name.replace(/\.[^.]+$/, "");
        const parent = this.folderId;
        const type = "file";

        const roomId = await RoomDoc.addRoom({ name, parent, oid, uid, type });

        this.roomId = roomId;
        this.name = name;

        await upload(roomId, file, (progress) => {
          this.progress = progress;
        });
      } catch (error) {
        console.error(error);
        this.error = error as Error;

        if (this.roomId != null) {
          await RoomDoc.deleteRoom(this.roomId);
        }
      }

      this.progress = 0;
      this.loading = false;
      this.done = true;
    },
  },
});
