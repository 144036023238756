










import Vue from "vue";
import { mapGetters } from "vuex";

import RoomContent from "~/components/RoomContent.vue";
import RoomEntrance from "~/components/RoomEntrance.vue";
import { RoomDoc } from "~/db";

export default Vue.extend({
  name: "Room",

  components: {
    RoomContent,
    RoomEntrance,
  },

  props: {
    roomId: {
      type: String,
      required: true,
    },
  },

  data() {
    return new (class {
      room?: RoomDoc = undefined;
    })();
  },

  computed: {
    exist(): boolean {
      return this.room?.data != null;
    },

    entry(): boolean {
      if (this.room == null) return false;
      if (this.room.data.oid === this.oid) return false;
      if (this.room.data.visibility === "public" && !this.signedIn) return true;
      return false;
    },

    visible(): boolean {
      if (this.room == null) return false;
      if (this.room.data.oid === this.oid) return true;
      if (this.room.data.visibility === "public" && this.signedIn) return true;
      if (this.admin) return true;
      return false;
    },

    ...mapGetters("user", ["signedIn", "oid", "admin"]),
  },

  async created(): Promise<void> {
    this.room = await RoomDoc.listenRoom(this.roomId);
  },

  destroyed(): void {
    if (this.room != null) {
      RoomDoc.unsubscribe(this.room);
    }
  },
});
