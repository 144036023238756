




































import Vue from "vue";

import OrganizationCreate from "~/components/OrganizationCreate.vue";
import OrganizationDelete from "~/components/OrganizationDelete.vue";
import OrganizationRow from "~/components/OrganizationRow.vue";
import TableHeader from "~/components/TableHeader.vue";

import { OrganizationDoc } from "~/db";
import Sortable from "~/utils/sortable";

type SortType = "name" | "id" | "amount" | "plan" | "timestamp";

export default Vue.extend({
  name: "Organizations",

  components: {
    OrganizationCreate,
    OrganizationDelete,
    OrganizationRow,
    TableHeader,
  },

  data() {
    return {
      organizations: [] as OrganizationDoc[],
      sortable: new Sortable<SortType>("timestamp", -1),
    };
  },

  computed: {
    sortedOrganizations(): OrganizationDoc[] {
      return this.organizations.slice().sort((a, b) => {
        let result = 0;

        switch (this.sortable.type) {
          case "name":
            result = a.data.name.localeCompare(b.data.name);
            break;
          case "id":
            result = a.id.localeCompare(b.id);
            break;
          case "amount":
            result = a.data.amount - b.data.amount;
            break;
          case "plan":
            result = (a.data.plan ?? 0) - (b.data.plan ?? 0);
            break;
          case "timestamp":
            result =
              a.data.createTime.toMillis() - b.data.createTime.toMillis();
            break;
        }

        return result * this.sortable.order;
      });
    },
  },

  created() {
    this.organizations = OrganizationDoc.listenOrganizations();

    this.$once("hook:destroy", () => {
      OrganizationDoc.unsubscribe(this.organizations);
    });
  },

  methods: {
    create(): void {
      const create = this.$refs.create as InstanceType<
        typeof OrganizationCreate
      >;
      create.open();
    },

    delete_(organization: OrganizationDoc): void {
      const delete_ = this.$refs.delete as InstanceType<
        typeof OrganizationDelete
      >;
      delete_.open(organization);
    },
  },
});
