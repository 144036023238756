








import Vue, { PropType } from "vue";

interface Error {
  code?: string;
  message: string;
}

export default Vue.extend({
  props: {
    error: {
      type: Error as PropType<Error | undefined>,
      default: undefined,
    },
  },

  computed: {
    header(): string | undefined {
      return this.display == null ? this.error?.code : undefined;
    },

    message(): string | undefined {
      return this.display ?? this.error?.message;
    },

    display(): string | undefined {
      switch (this.error?.code) {
        case "auth/email-already-exists":
          // The email address is already in use by another account.
          return "メールアドレスはすでに別のアカウントで使用されています。";
        case "auth/invalid-email":
          // The email address is improperly formatted.
          return "メールアドレスの形式が正しくありません。";
        case "auth/user-not-found":
          // There is no user record corresponding to this identifier. The user may have been deleted.
          return "このメールアドレスに対応するユーザーは存在しません。ユーザーが削除された可能性があります。";
        case "auth/wrong-password":
          // The password is invalid or the user does not have a password.
          return "パスワードが無効であるか、パスワードを設定していません。";
      }

      return undefined;
    },
  },
});
