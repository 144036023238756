import firebase from "~/utils/firebase";

import { BaseDocument } from "./document";

interface FolderData {
  name: string;
  parent: string | null;
  oid: string;
  owner: string;
  creator: string;
  grantees: string[];

  createTime: firebase.firestore.Timestamp;
  updateTime: firebase.firestore.Timestamp;
}

const dataConverter: firebase.firestore.FirestoreDataConverter<FolderData> = {
  toFirestore(folder: FolderData) {
    const {
      name,
      parent,
      oid,
      owner,
      creator,
      grantees,
      createTime,
      updateTime,
    } = folder;

    return {
      name,
      parent,
      oid,
      owner,
      creator,
      grantees,
      createTime,
      updateTime,
    };
  },

  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ) {
    const data = snapshot.data(options);
    const {
      name,
      parent,
      oid,
      owner,
      creator,
      grantees,
      createTime,
      updateTime,
    } = {
      ...{
        name: "",
        parent: null,
        oid: "",
        owner: "",
        creator: "",
        grantees: [""],
        createTime: new firebase.firestore.Timestamp(0, 0),
        updateTime: new firebase.firestore.Timestamp(0, 0),
      },
      ...(data as Partial<FolderData>),
    };

    return {
      name,
      parent,
      oid,
      owner,
      creator,
      grantees,
      createTime,
      updateTime,
    };
  },
};

export class FolderDoc extends BaseDocument<FolderData> {
  static collection(): firebase.firestore.CollectionReference<FolderData> {
    return firebase
      .firestore()
      .collection("folders")
      .withConverter(dataConverter);
  }

  static async addFolder(params: {
    name: string;
    parent: string | null;
    oid: string;
    uid: string;
  }): Promise<string> {
    return this.add(this.collection(), {
      name: params.name,
      parent: params.parent,
      oid: params.oid,
      owner: params.uid,
      creator: params.uid,
      grantees: [""],
    });
  }

  static async getFolder(folderId: string): Promise<FolderDoc | undefined> {
    return this.getDocument(this.collection().doc(folderId));
  }

  static listenFolders(params: {
    oid: string;
    owner?: boolean;
    uid?: string;
    gid?: string[];
  }): FolderDoc[] {
    let query = this.collection().where("oid", "==", params.oid);

    if (!params.owner) {
      const grantees = [""];

      if (params.uid != null) {
        grantees.push(params.uid);
      }

      if (params.gid != null) {
        grantees.push(...params.gid);
      }

      query = query.where("grantees", "array-contains-any", grantees);
    }

    return this.listenDocuments(query);
  }
}
