








































import Vue from "vue";
import { mapGetters } from "vuex";

import SignInForm from "~/components/SignInForm.vue";
import auth from "~/utils/auth";
import $ from "~/utils/jquery-loader";

export default Vue.extend({
  name: "SignIn",

  components: { SignInForm },

  computed: {
    link() {
      return auth.isLink();
    },

    ...mapGetters("user", ["uid"]),
  },

  watch: {
    uid(value) {
      if (value == null) return;

      this.callback();
    },
  },

  mounted() {
    $(this.$el).find(".menu .item").tab();
  },

  methods: {
    callback() {
      const value = this.$route.query.url;
      const url = typeof value === "object" ? value[0] : value;
      this.$router.replace(url || "/");
    },
  },
});
