








































































































































































import Vue from "vue";
import { mapState, mapMutations, mapGetters } from "vuex";

import { UserDoc } from "~/db";
import auth from "~/utils/auth";
import firebase from "~/utils/firebase";

import TextField from "~/components/TextField.vue";
import PasswordField from "~/components/PasswordField.vue";

export default Vue.extend({
  name: "MyPage",

  components: {
    TextField,
    PasswordField,
  },

  data() {
    return {
      user: undefined as UserDoc | undefined,
      email: "" as string | undefined | null,
      nameEditable: false,
      emailEditable: false,
      passwordEditable: false,
      hasPassword: false,
    };
  },

  computed: {
    passwordState(): string {
      return this.hasPassword ? "設定済み" : "未設定";
    },

    minLineLength: {
      ...mapState("user", { get: "minLineLength" }),
      ...mapMutations("user", { set: "minLineLength" }),
    },

    maxLineLength: {
      ...mapState("user", { get: "maxLineLength" }),
      ...mapMutations("user", { set: "maxLineLength" }),
    },

    ...mapGetters("user", ["uid"]),
  },

  async created() {
    this.user = await UserDoc.listenUser(this.uid);

    this.$once("hook:destroy", () => {
      UserDoc.unsubscribe(this.user);
    });

    // 認証用のメールを設定する
    this.email = auth.email;

    // パスワード認証が登録されているか確認する
    if (this.email != null) {
      const providers = await firebase
        .auth()
        .fetchSignInMethodsForEmail(this.email);

      if (providers.includes("password")) {
        this.hasPassword = true;
      }
    }
  },

  methods: {
    toast(e: Element) {
      $(e).toast({ displayTime: 5000, position: "top center" });
    },

    async editName() {
      this.nameEditable = true;

      await this.$nextTick();

      (this.$refs.name as InstanceType<typeof TextField>).focus();
    },

    async onNameChange(name: string) {
      if (name.length === 0) return;

      if (this.user) {
        await this.user.update({ name });

        // vuexでのnameの置き換え（メニューの名前が即時反映されるように）
        this.$store.commit("user/updateName", name);

        this.toast(this.$refs.nameToast as Element);
      }
    },

    onNameEnd() {
      this.nameEditable = false;
    },

    async editEmail() {
      this.emailEditable = true;

      await this.$nextTick();

      (this.$refs.email as InstanceType<typeof TextField>).focus();
    },

    async onEmailChange(email: string) {
      if (email.length === 0) return;

      try {
        await firebase.firestore().runTransaction(async (transaction) => {
          // Userのメールアドレスを変更
          if (this.user != undefined) {
            transaction.update(this.user.ref, { email });
          } else {
            throw new Error("User not found");
          }

          // Authのメールアドレスを変更
          if (auth.user) {
            await auth.user.updateEmail(email).then(() => {
              this.email = email;

              this.toast(this.$refs.emailToast as Element);
            });
          } else {
            throw new Error("Auth not found");
          }
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        switch (error.code) {
          case "auth/invalid-email":
            this.toast(this.$refs.invalidEmailToast as Element);
            break;
          case "auth/email-already-in-use":
            this.toast(this.$refs.emailAlreadyInUseToast as Element);
            break;
          default:
            this.toast(this.$refs.emailErrorToast as Element);
            break;
        }

        console.error(error);
      }
    },

    onEmailEnd() {
      this.emailEditable = false;
    },

    async editPassword() {
      this.passwordEditable = true;

      await this.$nextTick();

      (this.$refs.password as InstanceType<typeof PasswordField>).focus();
    },

    onPasswordChange(password: string) {
      if (password.length === 0) return;

      auth
        .updatePassword(password)
        .then(() => {
          this.hasPassword = true;

          this.toast(this.$refs.passwordToast as Element);
        })
        .catch((error) => {
          switch (error.code) {
            case "auth/requires-recent-login":
              this.goToSignIn();
              break;
            case "auth/weak-password":
              this.toast(this.$refs.weakPasswordToast as Element);
              break;
            default:
              this.toast(this.$refs.passwordErrorToast as Element);
              break;
          }

          console.error(error);
        });
    },

    onPasswordEnd() {
      this.passwordEditable = false;
    },

    goToSignIn(): void {
      this.$router.push({
        name: "signin",
        query: { url: location.pathname + location.search + location.hash },
      });
    },

    onLineLengthChange(): void {
      this.toast(this.$refs.lineLengthToast as Element);
    },
  },
});
