







































import Vue, { PropType } from "vue";

import ErrorMessage from "~/components/ErrorMessage.vue";
import Modal from "~/components/Modal.vue";

export default Vue.extend({
  components: { ErrorMessage, Modal },

  props: {
    autofocus: {
      type: Boolean,
      default: true,
    },

    form: {
      type: Boolean,
      default: true,
    },

    title: {
      type: String,
      default: undefined,
    },

    closeTitle: {
      type: String,
      default: undefined,
    },

    submitTitle: {
      type: String,
      default: undefined,
    },

    submitStyle: {
      type: String,
      default: "positive",
    },

    loading: {
      type: Boolean,
      default: false,
    },

    error: {
      type: Object as PropType<Error>,
      default: undefined,
    },
  },

  data() {
    return {};
  },

  computed: {
    formClass(): unknown {
      return {
        loading: this.loading,
        error: this.error != null,
      };
    },
  },

  methods: {
    modal(): InstanceType<typeof Modal> {
      return this.$refs.modal as InstanceType<typeof Modal>;
    },

    open(): void {
      this.modal().open();
    },

    async close(): Promise<void> {
      await this.$nextTick();
      this.modal().close();
    },

    submit(): void {
      this.$emit("submit");
    },

    closed(): void {
      this.$emit("closed");
    },
  },
});
