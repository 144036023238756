
















import Vue, { PropType } from "vue";
import { Location } from "vue-router";

import TextField from "~/components/TextField.vue";
import { FolderDoc } from "~/db";

export default Vue.extend({
  components: {
    TextField,
  },

  props: {
    folder: {
      type: Object as PropType<FolderDoc | null>,
      default: null,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    root(): boolean {
      return this.folder?.id == null;
    },

    to(): Location {
      if (this.folder == null) {
        return { name: "home", params: {} };
      }

      // TODO: oid

      return { name: "folder", params: { folderId: this.folder.id } };
    },
  },

  methods: {
    change(name: string) {
      this.folder?.update({ name });
    },
  },
});
