






import Vue from "vue";

import $ from "~/utils/jquery-loader";

export default Vue.extend({
  props: {
    value: {
      type: Number as () => number | null,
      default: null,
    },
  },

  computed: {
    indeterminate(): boolean {
      return this.value == null;
    },
  },

  watch: {
    value() {
      this.update();
    },
  },

  mounted() {
    this.update();
  },

  methods: {
    update() {
      const total = 1;
      const value = this.value ?? 1;

      $(this.$el).progress({ total, value });
    },
  },
});
