



















import Vue from "vue";

import Modal from "~/components/Modal.vue";
import RecodingExportModalContent from "~/components/RecodingExportModalContent.vue";

export default Vue.extend({
  components: { Modal, RecodingExportModalContent },

  data() {
    return {
      loading: false,
      error: undefined as Error | undefined,
    };
  },

  computed: {
    formClass(): unknown {
      return {
        loading: this.loading,
      };
    },
  },

  methods: {
    modal(): InstanceType<typeof Modal> {
      return this.$refs.modal as InstanceType<typeof Modal>;
    },

    open(): void {
      this.modal().open();
    },

    async close(): Promise<void> {
      await this.$nextTick();
      this.modal().close();
    },

    closed(): void {
      this.loading = false;
    },

    recodingExport(): void {
      const modalContent = this.$refs.modalContent as InstanceType<
        typeof RecodingExportModalContent
      >;
      const currentTab = modalContent.getCurrentTab();

      switch (currentTab) {
        case "word": {
          const showName =
            modalContent.speakerName === "display" ? true : false;
          const onlyMarkedRow =
            modalContent.outputRange === "onlyMarkedRow" ? true : false;

          this.$emit("exportToWord", showName, onlyMarkedRow);

          break;
        }
        case "sub": {
          switch (modalContent.sub) {
            case "subrip":
              this.$emit("exportToSubRip");
              break;
            case "subviewer":
              this.$emit("exportToSubViewer");
              break;
          }

          break;
        }
      }

      this.close();
    },
  },
});
