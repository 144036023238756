
















































































import Vue from "vue";
import { mapGetters } from "vuex";

import * as db from "~/db";
import { OrganizationDoc } from "~/db";
import { durationH } from "~/filters/time";

import Dropdown from "~/components/Dropdown.vue";
import TextField from "~/components/TextField.vue";

export default Vue.extend({
  name: "Organization",

  components: {
    Dropdown,
    TextField,
  },

  data() {
    return {
      organization: undefined as OrganizationDoc | undefined,
      editable: false,
      users: [] as db.Profile[],
    };
  },

  computed: {
    status(): string {
      if (this.organization?.data.plan == undefined) {
        return "トライアル（10時間）";
      }

      const plan = this.organization.data.plan;

      if (plan === 0) {
        return "未契約";
      }

      return `契約中（${durationH(plan)}時間/月）`;
    },

    getContact(): string {
      return this.organization?.data.contact || "";
    },

    // プロファイルの作成日時でソートする
    sortedUsers(): db.Profile[] {
      return this.users.slice().sort((a, b) => {
        return b.createTime.toMillis() - a.createTime.toMillis();
      });
    },

    ...mapGetters("user", ["oid"]),
  },

  async created() {
    this.users = db.getProfiles();

    this.organization = await OrganizationDoc.listenOrganization(this.oid);

    this.$once("hook:destroy", () => {
      OrganizationDoc.unsubscribe(this.organization);
    });
  },

  methods: {
    toast(e: Element) {
      $(e).toast({ displayTime: 5000, position: "top center" });
    },

    async editName() {
      this.editable = true;

      await this.$nextTick();

      (this.$refs.name as InstanceType<typeof TextField>).focus();
    },

    onNameChange(name: string) {
      if (this.organization) {
        this.organization.update({ name });

        this.toast(this.$refs.nameToast as Element);
      }
    },

    onNameEnd() {
      this.editable = false;
    },

    onContactChange() {
      const dropdown = this.$refs.contactUser as InstanceType<typeof Dropdown>;

      if (this.organization) {
        this.organization.update({ contact: dropdown.getValue() });

        this.toast(this.$refs.contactUserToast as Element);
      }
    },
  },
});
