import jQuery from "jquery";

declare global {
  interface Window {
    jQuery: JQueryStatic;
    $: JQueryStatic;
  }
}

window.jQuery = window.$ = jQuery;

export default jQuery;
