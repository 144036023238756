






































import Vue from "vue";

import Modal from "~/components/Modal.vue";

import regions from "~/utils/languages";

export default Vue.extend({
  components: { Modal },

  data() {
    return {
      regions,
      languageCode: "",
    };
  },

  methods: {
    modal(): InstanceType<typeof Modal> {
      return this.$refs.modal as InstanceType<typeof Modal>;
    },

    open(): void {
      this.modal().open();
    },

    select(languageCode: string): void {
      this.languageCode = languageCode;
      this.close();
    },

    close(): void {
      this.modal().close();
    },

    closed() {
      if (this.languageCode) {
        this.$emit("selected", this.languageCode);
      }
    },
  },
});
