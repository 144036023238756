import { OrganizationDoc, UsageDoc } from "~/db";
import { usageAmount, usageDate } from "~/filters/usage";

import pdfMake from "pdfmake/build/pdfmake";
import { TDocumentDefinitions } from "pdfmake/interfaces";
import ipaexg from "~/assets/fonts/ipaexg.ttf";

export const createPdf = (
  organization: OrganizationDoc | undefined,
  usages: UsageDoc[]
): void => {
  const font = `${self.origin}${ipaexg}`;

  const name = organization ? organization.data.name : "";

  const tableBody = [];

  tableBody.push([
    { text: "ご利用年月", style: "tableHeader" },
    { text: "利用時間", style: "tableHeader" },
  ]);

  usages.forEach((usage) => {
    tableBody.push([
      { text: usageDate(usage), style: "tableBody" },
      { text: usageAmount(usage), style: "tableBody" },
    ]);
  });

  pdfMake.fonts = {
    ipaexg: {
      normal: font,
      bold: font,
      italics: font,
      bolditalics: font,
    },
  };

  const docDefinition: TDocumentDefinitions = {
    content: [
      // タイトル
      {
        text: "MOZICA ご利用時間",
        style: "header",
      },

      // 宛先
      {
        text: `${name} 様`,
        style: "to",
      },

      // 表
      {
        table: {
          headerRows: 1,
          widths: ["*", "*"],
          body: tableBody,
        },
      },
    ],

    // ページ番号
    footer: (currentPage, pageCount) => {
      return {
        text: `${currentPage.toString()}/${pageCount}`,
        style: "footer",
      };
    },

    // スタイル
    defaultStyle: {
      font: "ipaexg",
      fontSize: 12,
    },
    styles: {
      header: {
        bold: true,
        fontSize: 20,
        alignment: "center",
        margin: [0, 30, 0, 40],
      },
      to: {
        fontSize: 16,
        margin: [0, 0, 0, 40],
      },
      tableHeader: {
        bold: true,
        alignment: "center",
        margin: [0, 6, 0, 6],
      },
      tableBody: {
        margin: [0, 6, 0, 6],
      },
      footer: {
        fontSize: 8,
        alignment: "center",
      },
    },
  };

  const date = new Date();
  const yearMonth =
    date.getFullYear() + (date.getMonth() + 1).toString().padStart(2, "0");

  pdfMake.createPdf(docDefinition).download(`mozica_利用実績_${yearMonth}`);
};
