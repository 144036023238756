





































































import Vue from "vue";

import ErrorMessage from "~/components/ErrorMessage.vue";
import auth from "~/utils/auth";
import firebase from "~/utils/firebase";

const emailForSignIn = "emailForSignIn";

export default Vue.extend({
  name: "SignInForm",

  components: { ErrorMessage },

  props: {
    type: {
      type: String,
      default: "",
      validator: (value): boolean => {
        return ["send", "password", "link"].includes(value);
      },
    },
  },

  data() {
    return new (class {
      email = localStorage.getItem(emailForSignIn) || auth.email;
      password = "";
      loading = false;
      expired = false;
      sent = false;
      error = undefined as firebase.auth.AuthError | undefined;
    })();
  },

  computed: {
    formClass(): unknown {
      return {
        loading: this.loading,
        error: this.error != null,
        info: this.expired,
      };
    },
  },

  created() {
    this.email = (this.$route.query.email as string) ?? this.email;

    if (this.type === "link" && this.email != null) {
      this.signInWithLink();
    }
  },

  methods: {
    sendLink() {
      const email = this.email;

      if (email == null) return;

      this.loading = true;
      this.error = undefined;

      const query = { ...this.$route.query };
      query.email = [];
      const route = this.$router.resolve({ query });
      const url = `${location.origin}${route.href}`;

      auth
        .sendLink(email, url)
        .then(() => {
          this.sent = true;
          localStorage.setItem(emailForSignIn, email);
          this.$router.replace({ name: "authMailInfo" });
        })
        .catch((error) => {
          console.error(error);

          this.error = error;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    signInWithLink() {
      const email = this.email;

      if (email == null) return;

      this.loading = true;
      this.error = undefined;

      auth
        .signInWithLink(email, location.href)
        .then(() => {
          localStorage.removeItem(emailForSignIn);
        })
        .catch((error) => {
          this.loading = false;

          switch (error.code) {
            case "auth/expired-action-code":
            case "auth/invalid-action-code":
              this.expired = true;
              return;
          }

          console.error(error);
          this.error = error;
        });
    },

    signInWithPassword() {
      const email = this.email;

      if (email == null) return;

      this.loading = true;
      this.error = undefined;

      auth
        .signInWithPassword(email, this.password)
        .then(() => {
          localStorage.removeItem(emailForSignIn);
        })
        .catch((error) => {
          this.loading = false;

          switch (error.code) {
            case "auth/expired-action-code":
            case "auth/invalid-action-code":
              this.expired = true;
              return;
          }

          console.error(error);
          this.error = error;
        });
    },
  },
});
