




















import Vue from "vue";

import UserBatchCreate from "~/components/UserBatchCreate.vue";
import UserCreate from "~/components/UserCreate.vue";
import UserDelete from "~/components/UserDelete.vue";
import UserList from "~/components/UserList.vue";

import auth from "~/utils/auth";
import { OrganizationDoc, UserDoc } from "~/db";

export default Vue.extend({
  name: "Users",

  components: { UserBatchCreate, UserCreate, UserDelete, UserList },

  data() {
    return {
      oid: undefined as string | undefined,
      organization: undefined as OrganizationDoc | undefined,
    };
  },

  watch: {
    $route: {
      handler: async function () {
        this.oid = this.$route.params.oid || auth.oid;

        if (this.oid == null) {
          this.organization = undefined;
        } else {
          this.organization = await OrganizationDoc.getOrganization(this.oid);
        }
      },
      immediate: true,
    },
  },

  methods: {
    batch(): void {
      const batch = this.$refs.batch as InstanceType<typeof UserBatchCreate>;
      batch.open();
    },

    create(): void {
      const create = this.$refs.create as InstanceType<typeof UserCreate>;
      create.open();
    },

    delete_(user: UserDoc): void {
      const delete_ = this.$refs.delete as InstanceType<typeof UserDelete>;
      delete_.open(user);
    },
  },
});
