
























import Vue, { PropType } from "vue";

import Checkbox from "~/components/Checkbox.vue";

import * as db from "~/db";

export default Vue.extend({
  components: { Checkbox },

  props: {
    group: {
      type: Object as PropType<db.Group | null>,
      default: null,
    },
  },

  data() {
    return {
      users: [] as db.Profile[],
      members: [] as db.GroupUser[],
      changes: {} as { [key: string]: boolean },
      query: "",
      dirty: false,
    };
  },

  computed: {
    filteredUsers(): db.Profile[] {
      return this.users.filter((user) => {
        return new RegExp(this.query, "i").test(user.name);
      });
    },

    isMember(): (user: db.Profile) => boolean {
      return (user: db.Profile) => {
        return this.members.some((member: db.GroupUser) => {
          return user.id === member.id;
        });
      };
    },
  },

  watch: {
    group() {
      this.members = this.group == null ? [] : db.getGroupUsers(this.group.id);
      this.dirty = false;
    },
  },

  created() {
    this.users = db.getProfiles();
  },

  methods: {
    changeUser(user: db.Profile, checked: boolean) {
      this.changes[user.id] = checked;
      this.dirty = true;
    },

    save() {
      if (this.group == null) return;

      db.changeGroupUsers(this.group.id, this.changes);
      this.dirty = false;
    },
  },
});
