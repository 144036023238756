




























import Vue from "vue";

import Modal from "~/components/Modal.vue";
import { FolderDoc } from "~/db";
import auth from "~/utils/auth";

export default Vue.extend({
  components: { Modal },

  props: {
    parent: {
      type: String as () => string | null,
      default: null,
    },
    oid: {
      type: String as () => string | undefined,
      default: undefined,
    },
  },

  data() {
    return {
      name: "",
      loading: false,
      error: undefined as Error | undefined,
    };
  },

  computed: {
    formClass(): unknown {
      return {
        loading: this.loading,
        error: this.error != null,
      };
    },
  },

  methods: {
    modal(): InstanceType<typeof Modal> {
      return this.$refs.modal as InstanceType<typeof Modal>;
    },

    open(): void {
      this.modal().open();
    },

    close(): void {
      this.modal().close();
    },

    create(): void {
      if (this.name.length === 0) return;
      if (auth.uid == null) return;

      const oid = this.oid || auth.oid;

      if (oid == null) return;

      this.loading = true;

      FolderDoc.addFolder({
        name: this.name,
        parent: this.parent,
        oid,
        uid: auth.uid,
      });

      this.close();
    },

    closed(): void {
      this.name = "";
      this.loading = false;
      this.error = undefined;
    },
  },
});
