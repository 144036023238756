import * as docx from "docx";
import { saveAs } from "file-saver";

export default class Word {
  private title?: string;
  private paragraphs: docx.Paragraph[] = [];

  constructor(title?: string) {
    this.title = title;

    const paragraph = new docx.Paragraph({
      text: title,
      heading: docx.HeadingLevel.TITLE,
    });

    this.paragraphs.push(paragraph);
  }

  add(name: string | undefined, text: string): void {
    name = name == null ? "" : `${name}\t`;

    const paragraph = new docx.Paragraph({
      text: `${name}${text}`,
      style: "Normal",
    });

    this.paragraphs.push(paragraph);
  }

  async save(needIndent = true): Promise<void> {
    const indentSize = needIndent ? 1600 : 0;

    const doc = new docx.Document({
      sections: [{ children: this.paragraphs }],
      title: this.title,
      styles: {
        paragraphStyles: [
          {
            id: "Title",
            name: "Title",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,
            run: {
              size: 60,
              bold: true,
              font: "Yu Gothic",
            },
            paragraph: {
              indent: {
                start: 0,
                hanging: 0,
              },
              spacing: {
                before: 160,
              },
            },
          },
          {
            id: "Normal",
            name: "Normal",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,
            run: {
              size: 24,
              font: "Yu Gothic",
            },
            paragraph: {
              indent: {
                start: indentSize,
                hanging: indentSize,
              },
              spacing: {
                before: 160,
              },
            },
          },
        ],
      },
    });

    const blob = await docx.Packer.toBlob(doc);

    saveAs(blob, `${this.title}.docx`);
  }
}
