






























































import Vue from "vue";

import PhraseCreate from "~/components/PhraseCreate.vue";
import PhraseDelete from "~/components/PhraseDelete.vue";
import PhraseRow from "~/components/PhraseRow.vue";

import { PhraseDoc } from "~/db";
import auth from "~/utils/auth";
import Sortable from "~/utils/sortable";

type SortType = "spoken" | "transcript" | "timestamp";

export default Vue.extend({
  name: "Dictionary",

  components: {
    PhraseCreate,
    PhraseDelete,
    PhraseRow,
  },

  data() {
    return {
      phrases: [] as PhraseDoc[],
      sortable: new Sortable<SortType>("timestamp", -1),
      query: "",
    };
  },

  computed: {
    sortedPhrases(): PhraseDoc[] {
      let phrases = this.phrases;

      if (this.query.trim().length === 0) {
        phrases = phrases.slice();
      } else {
        const reg = new RegExp(this.query, "i");

        phrases = phrases.filter((phrase) => {
          return (
            reg.test(phrase.data.spoken) || reg.test(phrase.data.transcript)
          );
        });
      }

      return phrases.sort((a, b) => {
        let result = 0;

        switch (this.sortable.type) {
          case "spoken":
            result = a.data.spoken.localeCompare(b.data.spoken);
            break;
          case "transcript":
            result = a.data.transcript.localeCompare(b.data.transcript);
            break;
          case "timestamp":
            result =
              (a.data.createTime?.toMillis() ?? Number.MAX_SAFE_INTEGER) -
              (b.data.createTime?.toMillis() ?? Number.MAX_SAFE_INTEGER);
            break;
        }

        return result * this.sortable.order;
      });
    },
  },

  created() {
    if (auth.oid == null) return;

    this.phrases = PhraseDoc.listenPhrases(auth.oid);

    this.$once("hook:destroy", () => {
      PhraseDoc.unsubscribe(this.phrases);
    });
  },

  methods: {
    create(): void {
      const create = this.$refs.create as InstanceType<typeof PhraseCreate>;
      create.open2();
    },

    edit(phrase: PhraseDoc): void {
      const create = this.$refs.create as InstanceType<typeof PhraseCreate>;
      create.open2(phrase);
    },

    delete_(phrase: PhraseDoc): void {
      const del = this.$refs.delete as InstanceType<typeof PhraseDelete>;
      del.open(phrase);
    },
  },
});
