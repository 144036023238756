import firebase from "~/utils/firebase";

import { BaseDocument } from "./document";

interface SpeechData {
  uid: string;
  name: string;
  languageCode: string;
  transcript: string;
  translation?: {
    [languageCode: string]: string;
  };
  words?: {
    transcript: string;
    spoken: string;
  };
  duration: number;
  mark?: boolean;

  createTime: firebase.firestore.Timestamp;
  updateTime: firebase.firestore.Timestamp;
}

const dataConverter: firebase.firestore.FirestoreDataConverter<SpeechData> = {
  toFirestore(speech: SpeechData) {
    const {
      uid,
      name,
      languageCode,
      transcript,
      translation,
      words,
      duration,
      mark,
      createTime,
      updateTime,
    } = speech;

    return {
      uid,
      name,
      languageCode,
      transcript,
      translation,
      words,
      duration,
      mark,
      createTime,
      updateTime,
    };
  },

  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ) {
    const data = snapshot.data(options);
    const {
      uid,
      name,
      languageCode,
      transcript,
      translation,
      words,
      duration,
      mark,
      createTime,
      updateTime,
    } = {
      ...{
        uid: "",
        name: "",
        languageCode: "",
        transcript: "",
        duration: 0,
        createTime: new firebase.firestore.Timestamp(0, 0),
        updateTime: new firebase.firestore.Timestamp(0, 0),
      },
      ...(data as Partial<SpeechData>),
    };

    return {
      uid,
      name,
      languageCode,
      transcript,
      translation,
      words,
      duration,
      mark,
      createTime,
      updateTime,
    };
  },
};

export class SpeechDoc extends BaseDocument<SpeechData> {
  static collection(
    roomId: string
  ): firebase.firestore.CollectionReference<SpeechData> {
    return firebase
      .firestore()
      .collection("rooms")
      .doc(roomId)
      .collection("speeches")
      .withConverter(dataConverter);
  }

  static listenSpeeches(
    roomId: string,
    callback?: (data: SpeechDoc) => void
  ): SpeechDoc[] {
    const query = this.collection(roomId).orderBy("createTime");
    return this.listenDocuments(query, callback);
  }

  get language() {
    return this.data.languageCode.split("-")[0];
  }
}
