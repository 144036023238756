







import Vue from "vue";
import Modal from "~/components/Modal.vue";

export default Vue.extend({
  components: {
    Modal,
  },

  data() {
    return {
      filename: undefined as string | undefined,
    };
  },

  computed: {
    modal(): InstanceType<typeof Modal> {
      return this.$refs.modal as InstanceType<typeof Modal>;
    },

    isImage(): boolean {
      return Boolean(
        ~String("png svg").indexOf(String(this.filename).slice(-3))
      );
    },
  },

  methods: {
    open(img: string) {
      this.filename = img;
      this.modal.open();
    },
  },
});
