import { UsageDoc } from "~/db";
import { durationHMMSSJP } from "~/filters/time";

export const usageDate = (usage: UsageDoc): string => {
  const id = usage.id;

  if (id === "trial") {
    return "トライアル";
  }

  const arr = id.split("-");
  return `${arr[0]}年${arr[1]}月`;
};

export const usageAmount = (usage: UsageDoc): string => {
  return durationHMMSSJP(usage.data.amount);
};
