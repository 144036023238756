import firebase from "~/utils/firebase";

import { BaseDocument } from "./document";

interface FileData {
  cancel?: boolean;

  createTime: firebase.firestore.Timestamp;
  updateTime: firebase.firestore.Timestamp;
}

const dataConverter: firebase.firestore.FirestoreDataConverter<FileData> = {
  toFirestore(file: Partial<FileData>) {
    const { cancel, createTime, updateTime } = file;

    return { cancel, createTime, updateTime };
  },

  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ) {
    const data: Partial<FileData> = snapshot.data(options);
    const { cancel, createTime, updateTime } = {
      ...{
        createTime: new firebase.firestore.Timestamp(0, 0),
        updateTime: new firebase.firestore.Timestamp(0, 0),
      },
      ...data,
    };

    return { cancel, createTime, updateTime };
  },
};

export class FileDoc extends BaseDocument<FileData> {
  static collection(): firebase.firestore.CollectionReference<FileData> {
    return firebase
      .firestore()
      .collection("files")
      .withConverter(dataConverter);
  }

  static async cancel(fileId: string) {
    await this.collection().doc(fileId).update({ cancel: true });
  }
}
