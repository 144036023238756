










































import Vue from "vue";

import ModalDialog from "~/components/ModalDialog.vue";
import RubyText from "~/components/RubyText.vue";
import { PhraseDoc, SpeechDoc } from "~/db";
import auth from "~/utils/auth";

export default Vue.extend({
  components: { ModalDialog, RubyText },

  data() {
    return {
      phrase: undefined as PhraseDoc | undefined,
      speeches: undefined as SpeechDoc[] | undefined,
      spoken: "",
      transcript: "",
    };
  },

  methods: {
    async open(speeches: SpeechDoc[]) {
      (this.$refs.dialog as InstanceType<typeof ModalDialog>).open();
      this.speeches = speeches;
    },

    async open2(phrase?: PhraseDoc) {
      (this.$refs.dialog as InstanceType<typeof ModalDialog>).open();

      if (phrase == null) return;

      this.phrase = phrase;
      this.spoken = phrase.data.spoken;
      this.transcript = phrase.data.transcript;
    },

    submit() {
      if (
        this.spoken.length === 0 ||
        this.transcript.length === 0 ||
        auth.oid == null ||
        auth.uid == null
      ) {
        return;
      }

      if (this.phrase == null) {
        PhraseDoc.addPhrase({
          spoken: this.spoken,
          transcript: this.transcript,
          oid: auth.oid,
          uid: auth.uid,
        });
      } else {
        this.phrase.update({
          spoken: this.spoken,
          transcript: this.transcript,
        });
      }

      (this.$refs.dialog as InstanceType<typeof ModalDialog>).close();
    },

    closed(): void {
      this.speeches = undefined;
      this.phrase = undefined;
      this.spoken = "";
      this.transcript = "";
    },
  },
});
