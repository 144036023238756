




import Vue, { PropType } from "vue";

import Sortable from "~/utils/sortable";

export default Vue.extend({
  props: {
    sortable: {
      type: Object as PropType<Sortable<string> | undefined>,
      default: undefined,
    },

    type: {
      type: String,
      default: "",
    },
  },

  computed: {
    class_(): string | null {
      return this.sortable == null ? "disable" : this.sortable.class(this.type);
    },
  },

  methods: {
    sort(): void {
      // ???
      // eslint-disable-next-line vue/no-mutating-props
      this.sortable?.sort(this.type);
    },
  },
});
