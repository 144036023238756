import Vue from "vue";
import VueRouter from "vue-router";
import VueMeta from "vue-meta";

import About from "~/views/About.vue";
import Admins from "~/views/Admins.vue";
import AuthMailInfo from "~/views/AuthMailInfo.vue";
import CaseStudy from "~/views/CaseStudy.vue";
import Contact from "~/views/Contact.vue";
import Development from "~/views/Development.vue";
import Dictionary from "~/views/Dictionary.vue";
import Folder from "~/views/Folder.vue";
import Groups from "~/views/Groups.vue";
import Guide from "~/views/Guide.vue";
import MyPage from "~/views/MyPage.vue";
import Organization from "~/views/Organization.vue";
import Organizations from "~/views/Organizations.vue";
import Room from "~/views/Room.vue";
import Settings from "~/views/Settings.vue";
import SignIn from "~/views/SignIn.vue";
import Subscribe from "~/views/Subscribe.vue";
import Usages from "~/views/Usages.vue";
import Users from "~/views/Users.vue";

import store from "~/store";

Vue.use(VueRouter);
Vue.use(VueMeta);

const routes = [
  {
    path: "/about",
    name: "about",
    component: About,
    meta: { pass: true, hideHeader: true, showFooter: true, robots: true },
  },
  {
    path: "/admins",
    name: "admins",
    component: Admins,
  },
  {
    path: "/authmailinfo",
    name: "authMailInfo",
    component: AuthMailInfo,
    meta: { pass: true, hideHeader: true },
  },
  {
    path: "/casestudy",
    name: "casestudy",
    component: CaseStudy,
    meta: { pass: true, hideHeader: true, showFooter: true, robots: true },
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact,
    meta: { pass: true, hideHeader: true, showFooter: true },
  },
  {
    path: "/development",
    name: "development",
    component: Development,
    meta: { admin: true },
  },
  {
    path: "/dictionary",
    name: "dictionary",
    component: Dictionary,
  },
  {
    path: "/folder/:folderId?",
    name: "folder",
    component: Folder,
    props: true,
  },
  {
    path: "/groups",
    name: "groups",
    component: Groups,
  },
  {
    path: "/guide",
    name: "guide",
    component: Guide,
    meta: { pass: true, hideHeader: true, showFooter: true, robots: true },
  },
  {
    path: "/home/:oid?",
    name: "home",
    component: Folder,
    props: true,
  },
  {
    path: "/mypage",
    name: "myPage",
    component: MyPage,
  },
  {
    path: "/organization",
    name: "organization",
    component: Organization,
  },
  {
    path: "/organizations",
    name: "organizations",
    component: Organizations,
  },
  {
    path: "/room/:roomId",
    name: "room",
    component: Room,
    meta: { wait: true },
    props: true,
  },
  {
    path: "/",
    name: "root",
    component: About,
    meta: { hideHeader: true, showFooter: true, robots: true },
  },
  {
    path: "/settings",
    name: "settings",
    component: Settings,
  },
  {
    path: "/signin",
    name: "signin",
    component: SignIn,
    meta: { wait: true },
  },
  {
    path: "/subscribe",
    name: "subscribe",
    component: Subscribe,
    meta: { pass: true, hideHeader: true, showFooter: true },
  },
  {
    path: "/usages",
    name: "usages",
    component: Usages,
  },
  {
    path: "/users/:oid?",
    name: "users",
    component: Users,
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
  scrollBehavior: (to, _from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach(async (to, _from, next) => {
  if (to.meta?.pass === true) {
    next();
    return;
  }

  await store.state.user.initialize;

  if (to.meta?.wait === true) {
    next();
    return;
  }

  if (to.meta?.admin === true) {
    if (store.getters["user/admin"]) {
      next();
      return;
    }
  } else if (
    store.getters["user/signedIn"] &&
    !store.getters["user/anonymous"]
  ) {
    if (to.name === "root") {
      next({ name: "home" });
    } else {
      next();
    }
    return;
  }

  if (to.name === "root") {
    next();
  } else {
    next({ name: "root" });
  }
});

export default router;
