


































































































































































































































































































































































































































































































































import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      muted: true,
    };
  },

  computed: {
    isMuted(): boolean {
      return this.muted;
    },
  },

  methods: {
    toggleMute_mozica(): void {
      const v = this.$refs.v_mozica as HTMLVideoElement;
      this.muted = v.muted = !v.muted;
    },
    toggleMute_language(): void {
      const v = this.$refs.v_language as HTMLVideoElement;
      this.muted = v.muted = !v.muted;
    },
    toggleMute_transration(): void {
      const v = this.$refs.v_transration as HTMLVideoElement;
      this.muted = v.muted = !v.muted;
    },
  },
});
