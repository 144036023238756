







import Vue from "vue";

import $ from "~/utils/jquery-loader";

export default Vue.extend({
  model: {
    prop: "checked",
    event: "change",
  },

  props: {
    checked: {
      type: Boolean,
      default: undefined,
    },
    name: {
      type: String,
      default: undefined,
    },
  },

  mounted() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const vm = this;

    $(this.$el).checkbox({
      onChange: function () {
        vm.$emit("change", this.checked);
      },
    });
  },
});
