










import Vue from "vue";

export default Vue.extend({
  model: {
    prop: "text",
    event: "change",
  },

  props: {
    text: {
      type: String,
      default: "",
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    contenteditable(): string {
      return this.editable ? "plaintext-only" : "false";
    },
  },

  methods: {
    focus() {
      const element = this.$el as HTMLElement;
      const range = document.createRange();
      const selection = getSelection();

      element.focus();
      range.selectNodeContents(element);
      selection?.removeAllRanges();
      selection?.addRange(range);
    },

    onKeyDown(event: KeyboardEvent) {
      const element = event.srcElement as HTMLElement;

      switch (event.keyCode) {
        case 13: // Enter
          this.$emit("enter");
          break;
        case 27: // Escape
          element.textContent = this.text;
          break;
        default:
          return;
      }

      event.preventDefault();
      element.blur();
    },

    onBlur(event: FocusEvent) {
      const element = event.srcElement as HTMLElement;
      const text = element.textContent;

      if (!text?.trim().length) {
        element.textContent = this.text;
      } else if (text !== this.text) {
        this.$emit("change", text);
      }

      this.$emit("end", text);
    },
  },
});
