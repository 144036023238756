



















import Vue, { PropType } from "vue";

import TextField from "~/components/TextField.vue";
import * as db from "~/db";

export default Vue.extend({
  components: {
    TextField,
  },

  props: {
    group: {
      type: Object as PropType<db.Group>,
      required: true,
    },
    selected: {
      type: Object as PropType<db.Group>,
      default: null,
    },
  },

  data() {
    return { editable: false };
  },

  methods: {
    select() {
      this.$emit("select", this.group);
    },

    async edit() {
      this.editable = true;
      await this.$nextTick();
      (this.$refs.name as InstanceType<typeof TextField>).focus();
    },

    delete_() {
      this.$emit("delete", this.group);
    },

    onNameChange(name: string) {
      db.setGroupName(this.group.id, name);
    },

    onNameEnd() {
      this.editable = false;
    },
  },
});
