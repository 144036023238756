























































































import Vue, { PropType } from "vue";

import SpeechChartAxis from "~/components/SpeechChartAxis.vue";
import { SpeechDoc } from "~/db";
import { user } from "~/filters/user";

export default Vue.extend({
  components: { SpeechChartAxis },

  filters: { user },

  props: {
    speeches: {
      type: Array as PropType<SpeechDoc[]>,
      required: true,
    },
    profiles: {
      type: Object as PropType<{ [uid: string]: Profile | undefined }>,
      required: true,
    },
  },

  data() {
    return {
      clientWidth: 60,
      clientHeight: 50,
      marginLeft: 40,
      marginRight: 20,
      marginTop: 20,
      marginBottom: 30,
      scrollOffset: 0,
      currentSpeech: undefined as SpeechDoc | undefined,
      hidden: {} as { [key: string]: boolean },
    };
  },

  computed: {
    width(): number {
      return this.clientWidth - this.marginLeft - this.marginRight;
    },

    height(): number {
      return this.clientHeight - this.marginTop - this.marginBottom;
    },

    origin(): { x: number; y: number } {
      return { x: this.marginLeft, y: this.clientHeight - this.marginBottom };
    },

    start(): number {
      const speech = this.speeches[0];
      return speech.data.createTime.toMillis();
    },

    time(): (speech: SpeechDoc) => number {
      return (speech: SpeechDoc) => {
        return (speech.data.createTime.toMillis() - this.start) / 1000;
      };
    },

    duration(): (speech: SpeechDoc) => number {
      return (speech: SpeechDoc) => {
        return -Math.ceil((speech.data.duration || 1) / 1000) * 5;
      };
    },

    barClass(): (speech: SpeechDoc) => string {
      return (speech: SpeechDoc) => {
        return this.hidden[speech.data.uid] ? "hidden" : "";
      };
    },

    userClass(): (speech: SpeechDoc) => string {
      return (speech: SpeechDoc) => {
        return user(this.profiles[speech.data.uid]);
      };
    },

    tooltipX(): number {
      if (this.currentSpeech == null) return 0;

      const x =
        this.origin.x + this.time(this.currentSpeech) - this.scrollOffset;

      return x < this.clientWidth / 2 ? x : x - 250;
    },

    tooltipY(): number {
      if (this.currentSpeech == null) return 0;

      return Math.max(
        this.origin.y + this.duration(this.currentSpeech) - 60,
        10
      );
    },
  },

  mounted() {
    const svg = this.$refs.svg as Element;

    const resizeObserver = new ResizeObserver((entries) => {
      const target = entries[0].target;

      this.clientWidth = target.clientWidth;
      this.clientHeight = target.clientHeight;
    });

    resizeObserver.observe(svg);

    const wheelListener = ((event: WheelEvent) => {
      event.preventDefault();

      const delta =
        Math.abs(event.deltaX) <= Math.abs(event.deltaY)
          ? event.deltaY
          : event.deltaX;

      this.scrollOffset += Math.max(delta, -this.scrollOffset);
    }) as (event: Event) => void;

    svg.addEventListener("wheel", wheelListener, { passive: false });

    this.$once("hook:beforeDestroy", () => {
      svg.removeEventListener("wheel", wheelListener);
    });
  },

  methods: {
    click(speech: SpeechDoc) {
      this.$emit("click", speech);
    },

    enter(speech: SpeechDoc) {
      this.currentSpeech = speech;
    },

    leave() {
      this.currentSpeech = undefined;
    },

    toggle(key: string) {
      this.$set(this.hidden, key, !this.hidden[key]);
    },
  },
});
