



















import Vue from "vue";

import ModalDialog from "~/components/ModalDialog.vue";
import { AdminDoc } from "~/db";

export default Vue.extend({
  components: { ModalDialog },

  data() {
    return {
      email: undefined as string | undefined,
      loading: false,
      error: undefined as Error | undefined,
    };
  },

  methods: {
    dialog(): InstanceType<typeof ModalDialog> {
      return this.$refs.dialog as InstanceType<typeof ModalDialog>;
    },

    open(): void {
      this.dialog().open();
    },

    close(): void {
      this.dialog().close();
    },

    async create(): Promise<void> {
      if (this.email == null) return;

      this.loading = true;

      await AdminDoc.addAdmin(this.email);

      this.loading = false;

      this.close();
    },

    closed(): void {
      this.email = undefined;
      this.loading = false;
      this.error = undefined;
    },
  },
});
