



































import Vue from "vue";
import QRCode from "qrcode.vue";

import Modal from "~/components/Modal.vue";

export default Vue.extend({
  components: {
    Modal,
    QRCode,
  },

  props: {
    roomId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      copied: false,
    };
  },

  computed: {
    url(): string {
      const resolved = this.$router.resolve({
        name: "room",
        params: { roomId: this.roomId },
      });

      return location.origin + resolved.href;
    },

    modal(): InstanceType<typeof Modal> {
      return this.$refs.modal as InstanceType<typeof Modal>;
    },
  },

  methods: {
    copy(): void {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(this.url);
      } else {
        const input = this.$refs.url as HTMLInputElement;

        input.select();
        document.execCommand("copy");
        input.setSelectionRange(null, null);
      }

      this.copied = true;

      setTimeout(() => {
        this.copied = false;
      }, 1000);
    },

    async fullscreen(): Promise<void> {
      const qr = this.$refs.qr as Element;
      await qr.requestFullscreen();
    },

    async exit(): Promise<void> {
      if (document.fullscreenElement) {
        await document.exitFullscreen();
      }
    },

    share(enable: boolean): void {
      this.$emit("share", enable);
      this.close();
    },

    open() {
      this.modal.open();
    },

    close() {
      this.modal.close();
    },
  },
});
