




























import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";

import Dropdown from "~/components/Dropdown.vue";

export default Vue.extend({
  components: {
    Dropdown,
  },

  computed: {
    ...mapGetters(["microphoneRecording"]),
  },

  methods: {
    icon(microphone: boolean) {
      return microphone ? "microphone icon" : "file audio outline icon";
    },

    create(microphone: boolean) {
      this.setMicrophoneRecording(microphone);
      this.$emit("create", microphone);
    },

    ...mapMutations(["setMicrophoneRecording"]),
  },
});
