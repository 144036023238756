
























import Vue from "vue";
import { mapGetters } from "vuex";

import { PortalTarget } from "portal-vue";

import UserButton from "~/components/UserButton.vue";

export default Vue.extend({
  name: "App",

  components: {
    PortalTarget,
    UserButton,
  },

  metaInfo () {
    return {
      meta: [
        { name: "robots", content: this.robots ? "index" : "none" },
      ],
    }
  },

  computed: {
    showHeader(): boolean {
      return this.$route.name != null && !this.$route.meta?.hideHeader;
    },

    showFooter(): boolean {
      return this.$route.meta?.showFooter;
    },

    robots(): boolean {
      return this.$route.meta?.robots;
    },

    ...mapGetters("user", ["owner"]),
  },

  mounted() {
    this.$el.addEventListener(
      "touchstart",
      () => {
        return;
      },
      { passive: true }
    );
  },
});
