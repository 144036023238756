






























import Vue from "vue";

import UserRow from "~/components/UserRow.vue";
import { UserDoc } from "~/db/user";
import Sortable from "~/utils/sortable";

type SortType = "email" | "timestamp";

export default Vue.extend({
  components: { UserRow },

  props: {
    oid: {
      type: String,
      default: "",
    },
  },

  data() {
    return new (class {
      users: UserDoc[] = [];
      sortable = new Sortable<SortType>("timestamp", -1);
    })();
  },

  computed: {
    emailClass(): string | null {
      return this.sortable.class("email");
    },

    timestampClass(): string | null {
      return this.sortable.class("timestamp");
    },

    sortedUsers(): UserDoc[] {
      return this.users.slice().sort((a, b) => {
        let result = 0;

        switch (this.sortable.type) {
          case "email":
            result = a.data.email.localeCompare(b.data.email);
            break;
          case "timestamp":
            result =
              a.data.createTime.toMillis() - b.data.createTime.toMillis();
            break;
          default:
            break;
        }

        return result * this.sortable.order;
      });
    },
  },

  watch: {
    oid: {
      handler: async function () {
        this.users = UserDoc.listenUsers(this.oid);
      },
      immediate: true,
    },
  },
});
