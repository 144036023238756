import firebase from "~/utils/firebase";

import { BaseDocument } from "./document";

interface PhraseData {
  spoken: string;
  transcript: string;
  oid: string;
  owner: string;
  creator: string;

  createTime: firebase.firestore.Timestamp;
  updateTime: firebase.firestore.Timestamp;
}

const dataConverter: firebase.firestore.FirestoreDataConverter<PhraseData> = {
  toFirestore(phrase: PhraseData) {
    const { spoken, transcript, oid, owner, creator, createTime, updateTime } =
      phrase;

    return { spoken, transcript, oid, owner, creator, createTime, updateTime };
  },

  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ) {
    const data = snapshot.data(options);
    const { spoken, transcript, oid, owner, creator, createTime, updateTime } =
      {
        ...{
          spoken: "",
          transcript: "",
          oid: "",
          owner: "",
          creator: "",
          createTime: new firebase.firestore.Timestamp(0, 0),
          updateTime: new firebase.firestore.Timestamp(0, 0),
        },
        ...(data as Partial<PhraseData>),
      };

    return { spoken, transcript, oid, owner, creator, createTime, updateTime };
  },
};

export class PhraseDoc extends BaseDocument<PhraseData> {
  static collection(): firebase.firestore.CollectionReference<PhraseData> {
    return firebase
      .firestore()
      .collection("phrases")
      .withConverter(dataConverter);
  }

  static async addPhrase(params: {
    spoken: string;
    transcript: string;
    oid: string;
    uid: string;
  }): Promise<string> {
    const { spoken, transcript, oid, uid } = params;
    return this.add(this.collection(), {
      spoken,
      transcript,
      oid,
      owner: uid,
      creator: uid,
    });
  }

  static listenPhrases(oid: string): PhraseDoc[] {
    const query = this.collection().where("oid", "==", oid);
    return this.listenDocuments(query);
  }
}
