



























import Vue, { PropType } from "vue";

import { OrganizationDoc } from "~/db";
import { durationHMM, durationHMMSS, localeDateTime } from "~/filters/time";

export default Vue.extend({
  props: {
    organization: {
      type: Object as PropType<OrganizationDoc>,
      required: true,
    },
  },

  computed: {
    amount(): string {
      return durationHMMSS(this.organization.data.amount);
    },

    limit(): string {
      return durationHMMSS(this.organization.data.limit);
    },

    plan(): string {
      return this.organization.data.plan == null
        ? "トライアル"
        : durationHMM(this.organization.data.plan);
    },

    createTime(): string {
      return localeDateTime(this.organization.data.createTime);
    },
  },

  methods: {
    delete(): void {
      this.$emit("delete", this.organization);
    },
  },
});
