

















































import Vue, { PropType } from "vue";

import ModalDialog from "~/components/ModalDialog.vue";
import { FolderDoc, RoomDoc } from "~/db";

export default Vue.extend({
  components: { ModalDialog },

  props: {
    allFolders: {
      type: Array as PropType<FolderDoc[]>,
      required: true,
    },

    from: {
      type: String as PropType<string | null>,
      default: null,
    },
  },

  data() {
    return {
      items: [] as (FolderDoc | RoomDoc)[],
      current: null as FolderDoc | null,
    };
  },

  computed: {
    dialog() {
      return this.$refs.dialog as InstanceType<typeof ModalDialog>;
    },

    folders(): FolderDoc[] {
      return this.allFolders
        .filter((folder) => {
          return folder.data.parent == this.current?.id;
        })
        .sort((a, b) => {
          return a.data.name.localeCompare(b.data.name);
        });
    },

    root(): boolean {
      return this.current == null;
    },
  },

  methods: {
    open(items: (FolderDoc | RoomDoc)[]): void {
      this.items = items;
      this.select(this.from);

      this.dialog.open();
    },

    close(): void {
      this.dialog.close();
    },

    back(): void {
      this.select(this.current?.data.parent || null);
    },

    home(): void {
      this.current = null;
    },

    select(folder: string | FolderDoc | null): void {
      if (typeof folder === "string") {
        this.current =
          this.allFolders.find((value) => {
            return value.id === folder;
          }) || null;
      } else {
        this.current = folder;
      }
    },

    self(folder: FolderDoc): boolean {
      return this.items.includes(folder);
    },

    async move(): Promise<void> {
      if (this.items.length > 0) {
        const batch = this.items[0].ref.firestore.batch();
        const parent = this.current?.id || null;

        for (const item of this.items) {
          batch.update(item.ref, { parent });
        }

        await batch.commit();
      }

      this.close();
    },

    closed(): void {
      this.items = [];
    },
  },
});
