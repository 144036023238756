









import Vue, { PropType } from "vue";

import { UsageDoc, OrganizationDoc } from "~/db";

import { durationHMMSSJP } from "~/filters/time";
import { usageAmount, usageDate } from "~/filters/usage";

export default Vue.extend({
  props: {
    usage: {
      type: Object as PropType<UsageDoc>,
      required: true,
    },
    organizations: {
      type: Array as PropType<OrganizationDoc[]>,
      required: true,
    },
    admin: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    getOrganization(): OrganizationDoc | undefined {
      return this.organizations.find((organization) => {
        return organization.id === this.usage.ref.parent.parent?.id;
      });
    },

    amount(): string {
      return usageAmount(this.usage);
    },

    plan(): string {
      const limit = this.usage.data.limit;
      return limit == 0 ? "-" : durationHMMSSJP(limit);
    },

    organizationName(): string {
      const organization = this.getOrganization;

      if (organization === undefined) {
        return "";
      }

      return organization.data.name;
    },

    useDate(): string {
      return usageDate(this.usage);
    },
  },
});
