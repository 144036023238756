













































import Vue from "vue";

import auth from "~/utils/auth";

export default Vue.extend({
  name: "Settings",

  data() {
    return {
      password: "",
      loading: false,
      error: undefined as firebase.auth.AuthError | undefined,
    };
  },

  computed: {
    formClass(): unknown {
      return {
        loading: this.loading,
        error: this.error != null,
      };
    },
  },

  methods: {
    updatePassword(): void {
      if (this.password.length === 0) return;

      this.loading = true;
      this.error = undefined;

      auth
        .updatePassword(this.password)
        .then(() => {
          this.password = "";
        })
        .catch((error) => {
          if (error.code === "auth/requires-recent-login") {
            this.goToSignIn();
            return;
          }

          console.error(error);
          this.error = error;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    goToSignIn(): void {
      this.$router.push({
        name: "signin",
        query: { url: location.pathname + location.search + location.hash },
      });
    },
  },
});
