import auth from "~/utils/auth";
import firebase from "~/utils/firebase";

export default async (
  roomId: string,
  file: File,
  callback: (progress: number) => void
): Promise<void> => {
  const { uid, oid } = auth;

  if (uid == null || oid == null) return;

  callback(0);

  const storage = firebase
    .app()
    .storage(`gs://${process.env.STORAGE_BUCKET_MEDIA}`);

  if (location.hostname === "localhost") {
    storage.useEmulator(location.hostname, 9199);
  }

  const metadata = {
    customMetadata: { uid, oid },
  };

  const uploadTask = storage.ref(roomId).put(file, metadata);

  uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
    callback(snapshot.bytesTransferred / snapshot.totalBytes);
  });

  await uploadTask.then();
};
