















import Vue from "vue";

import Modal from "~/components/Modal.vue";

import { OrganizationDoc } from "~/db";

export default Vue.extend({
  components: { Modal },

  data() {
    return {
      organization: undefined as OrganizationDoc | undefined,
    };
  },

  methods: {
    modal(): InstanceType<typeof Modal> {
      return this.$refs.modal as InstanceType<typeof Modal>;
    },

    open(organization: OrganizationDoc): void {
      this.organization = organization;
      this.modal().open();
    },

    close(): void {
      this.modal().close();
    },

    async delete(): Promise<void> {
      if (this.organization != null) {
        await this.organization.delete();
      }

      this.close();
    },
  },
});
