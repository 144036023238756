import { Store } from "vuex";

import auth from "~/utils/auth";

const root = {};

export const plugin = (store: Store<typeof root>): void => {
  auth.observe(() => {
    const payload = {
      uid: auth.user?.uid,
      user: auth.user,
      claims: auth.claims,
    };

    store.dispatch("user/auth", payload);
  });
};
