




























































import Vue, { PropType } from "vue";

import LanguageSelect from "~/components/LanguageSelect.vue";
import { RoomDoc } from "~/db";

import AudioRecorder from "~/utils/audio-recorder";
import $ from "~/utils/jquery-loader";
import regions, { Language } from "~/utils/languages";
import { io, Socket } from "~/utils/socket";

export default Vue.extend({
  components: { LanguageSelect },

  props: {
    room: {
      type: Object as PropType<RoomDoc>,
      required: true,
    },

    fontSize: {
      type: Number as PropType<number | null>,
      default: null,
    },

    darkMode: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return new (class {
      socket!: Socket;
      recorder!: AudioRecorder;
      connected = false;
      transcript = "";
      pushed = false;
      recording = false;
      available = true;
    })();
  },

  computed: {
    style() {
      return this.fontSize && { fontSize: `${this.fontSize}em` };
    },

    language(): Language {
      const flatRegions = regions.reduce((previous, current) => {
        previous.push(...current.languages);
        return previous;
      }, [] as Language[]);

      return (
        flatRegions.find(
          (language) => language.code === this.$store.state.user.languageCode
        ) || { country: "Japan", name: "日本語", code: "ja-JP", flag: "jp" }
      );
    },

    languageCode(): string {
      return this.language.code;
    },
  },

  async created(): Promise<void> {
    this.recorder = new AudioRecorder();

    this.socket = io(this.room.id, this.languageCode);

    this.socket.on("connect", () => {
      this.connected = true;

      if (this.recording) {
        this.record();
      }
    });

    this.socket.on("disconnect", () => {
      this.connected = false;
      this.transcript = "";
      this.recorder.stop();
    });

    this.socket.on("available", (available: boolean) => {
      if (!available && this.recording) {
        this.recording = false;
        this.pushed = false;
        this.socket.emit("stop");
        this.recorder.stop();
      }

      this.available = available;
    });

    this.socket.on(
      "recognize",
      (data: { isFinal: boolean; transcript: string }) => {
        this.transcript = data.isFinal ? "" : data.transcript;
      }
    );

    document.body.addEventListener("keydown", this.onKeyDown);
    document.body.addEventListener("keyup", this.onKeyUp);
  },

  destroyed() {
    this.recorder.stop();
    this.socket.close();

    document.body.removeEventListener("keydown", this.onKeyDown);
    document.body.removeEventListener("keyup", this.onKeyUp);
  },

  methods: {
    onKeyDown(event: KeyboardEvent) {
      if (
        event.target === document.body &&
        event.code == "Space" &&
        !event.altKey &&
        !event.ctrlKey &&
        !event.metaKey &&
        !event.shiftKey &&
        !event.repeat &&
        this.connected &&
        this.available &&
        !this.recording
      ) {
        this.pushed = true;
        this.recording = true;
        this.record();
      }
    },

    onKeyUp(event: KeyboardEvent) {
      if (event.code === "Space" && this.pushed) {
        this.pushed = false;
        this.recording = false;
        this.socket.emit("stop");
        this.recorder.stop();
        event.preventDefault();
      }
    },

    onMicrophoneClick() {
      if (!this.available || this.pushed) {
        return;
      }

      this.recording = !this.recording;

      if (this.recording) {
        this.record();
      } else {
        this.socket.emit("stop");
        this.recorder.stop();
      }
    },

    onLanguageClick() {
      const languageSelect = this.$refs.languageSelect as InstanceType<
        typeof LanguageSelect
      >;
      languageSelect.open();
    },

    async onLanguageSelect(languageChanged: string) {
      this.$store.commit("user/languageCode", languageChanged);
      this.socket.emit("language", languageChanged);

      await this.$nextTick();

      const toast = this.$refs.toast as Element;
      $(toast).toast({ position: "bottom center" });
    },

    record() {
      this.socket.emit("recognize", {
        sampleRate: 16000,
        languageCode: this.languageCode,
      });

      this.recorder.record((data) => {
        this.socket.volatile.emit("audio", data.buffer);
      });
    },
  },
});
