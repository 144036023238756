














import Vue from "vue";

import Modal from "~/components/Modal.vue";
import { RoomDoc } from "~/db";

export default Vue.extend({
  components: { Modal },

  data() {
    return {
      room: undefined as RoomDoc | undefined,
    };
  },

  computed: {
    modal(): InstanceType<typeof Modal> {
      return this.$refs.modal as InstanceType<typeof Modal>;
    },
  },

  methods: {
    open(room: RoomDoc): void {
      this.room = room;
      this.modal.open();
    },

    close(): void {
      this.modal.close();
    },

    del(): void {
      this.room?.ref.delete();

      this.close();
    },

    closed(): void {
      this.room = undefined;
    },
  },
});
