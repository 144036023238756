

















import Vue from "vue";

export default Vue.extend({
  props: {
    horizontal: {
      type: Boolean,
      required: true,
    },

    length: {
      type: Number,
      required: true,
    },

    offset: {
      type: Number,
      default: 0,
    },

    tickInterval: {
      type: Number,
      required: true,
    },

    tickStep: {
      type: Number,
      required: true,
    },

    tickSkip: {
      type: Number,
      default: 0,
    },

    tickFormat: {
      type: String,
      default: "number",
    },
  },

  computed: {
    tickCount(): number {
      return Math.ceil(this.length / this.tickInterval);
    },

    tickOffset() {
      return (n: number) => {
        const interval = this.tickInterval;
        const offset = ((this.offset + interval - 1) % interval) + 1;
        return interval * n - offset;
      };
    },

    tickValue() {
      return (n: number) => {
        const num = Math.ceil(this.offset / this.tickInterval + n - 1);

        if (num % (this.tickSkip + 1) !== 0) return;

        const value = num * this.tickStep;

        if (this.tickFormat === "time") {
          const min = Math.floor(value / 60);
          const sec = String(value % 60).padStart(2, "0");

          return `${min}:${sec}`;
        }

        return value;
      };
    },
  },
});
