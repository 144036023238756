
















import Vue from "vue";

import auth from "~/utils/auth";
import * as db from "~/db";
import { UserDoc } from "~/db";

export default Vue.extend({
  data() {
    return new (class {
      name = "";
      loading = false;
    })();
  },

  methods: {
    async next() {
      if (this.loading) return;

      this.loading = true;

      const credential = await auth.signInAnonymously().catch((error) => {
        console.error(error);
      });

      if (credential == null) return;

      const uid = credential.user?.uid;

      if (uid == null) return;

      const name = this.name || "ゲスト";

      await UserDoc.collection().doc(uid).set({ name }, { merge: true });
      await db.profiles().doc(uid).set({ name }, { merge: true });
    },
  },
});
