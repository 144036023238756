













import Vue, { PropType } from "vue";
import { mapGetters, mapState } from "vuex";

import TextField from "~/components/TextField.vue";
import { SpeechDoc } from "~/db";

export default Vue.extend({
  components: { TextField },

  props: {
    speech: {
      type: Object as PropType<SpeechDoc>,
      required: true,
    },
    ownLanguage: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    userLanguage(): string {
      return this.languageCode.split("-")[0];
    },

    originalLanguage(): string {
      return this.speech.data.languageCode.split("-")[0];
    },

    transcript(): string | undefined {
      if (this.ownLanguage) {
        if (this.originalLanguage === this.userLanguage) {
          return this.speech.data.transcript;
        } else {
          return this.speech.data.translation?.[this.userLanguage];
        }
      } else {
        if (this.originalLanguage === this.userLanguage) {
          return;
        } else {
          return this.speech.data.transcript;
        }
      }
    },

    separating(): boolean {
      return (
        (this.ownLanguage ? this.userLanguage : this.originalLanguage) !== "ja"
      );
    },

    ...mapState("user", ["languageCode"]),
    ...mapGetters("user", ["anonymous"]),
  },

  methods: {
    onFocusIn(event: FocusEvent) {
      if (
        event.currentTarget instanceof Node &&
        event.relatedTarget instanceof Node &&
        event.currentTarget.contains(event.relatedTarget)
      ) {
        return;
      }

      this.$emit("focus", this.speech);
    },

    onFocusOut(event: FocusEvent) {
      if (
        event.currentTarget instanceof Node &&
        event.relatedTarget instanceof Node &&
        event.currentTarget.contains(event.relatedTarget)
      ) {
        return;
      }

      this.$emit("blur", this.speech);
    },

    onChange(text: string) {
      if (this.ownLanguage) {
        if (this.originalLanguage === this.userLanguage) {
          this.speech.update({ transcript: text });
        } else {
          this.speech.update({ [`translation.${this.userLanguage}`]: text });
        }
      } else {
        if (this.originalLanguage === this.userLanguage) {
          return;
        } else {
          this.speech.update({ transcript: text });
        }
      }
    },

    onEnter() {
      this.$emit("enter", this);
    },

    focus() {
      (this.$el as HTMLElement).focus();
    },
  },
});
