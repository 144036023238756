













import Vue from "vue";

import ModalDialog from "~/components/ModalDialog.vue";
import { PhraseDoc } from "~/db";

export default Vue.extend({
  components: { ModalDialog },

  data() {
    return {
      phrase: null as PhraseDoc | null,
    };
  },

  computed: {
    dialog(): InstanceType<typeof ModalDialog> {
      return this.$refs.dialog as InstanceType<typeof ModalDialog>;
    },
  },

  methods: {
    open(phrase: PhraseDoc): void {
      this.phrase = phrase;
      this.dialog.open();
    },

    close(): void {
      this.dialog.close();
    },

    async delete_(): Promise<void> {
      await this.phrase?.ref.delete();
      this.close();
    },

    closed(): void {
      this.phrase = null;
    },
  },
});
