




import Vue from "vue";

import $ from "~/utils/jquery-loader";

export default Vue.extend({
  name: "Dropdown",

  props: {
    action: {
      type: String,
      default: undefined,
      validator: (value: string): boolean => {
        return ["activate", "select", "combo", "nothing", "hide"].includes(
          value
        );
      },
    },

    match: {
      type: String,
      default: undefined,
      validator: (value: string): boolean => {
        return ["both", "value", "text"].includes(value);
      },
    },

    fullTextSearch: {
      type: Boolean,
      default: undefined,
    },
  },

  mounted() {
    $(this.$el).dropdown({
      action: this.action as
        | "activate"
        | "select"
        | "combo"
        | "nothing"
        | "hide",
      match: this.match as "both" | "value" | "text" | undefined,
      fullTextSearch: this.fullTextSearch,
      onAdd() {
        $(this).dropdown("hide");
      },
    });
  },

  methods: {
    setSelected(value: string | string[] | undefined) {
      $(this.$el).dropdown("set selected", value);
    },

    getValue() {
      return $(this.$el).dropdown("get value") as string;
    },
  },
});
