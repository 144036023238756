













import Vue from "vue";

import Modal from "~/components/Modal.vue";

import * as db from "~/db";

export default Vue.extend({
  components: { Modal },

  data() {
    return {
      group: undefined as db.Group | undefined,
    };
  },

  methods: {
    modal(): InstanceType<typeof Modal> {
      return this.$refs.modal as InstanceType<typeof Modal>;
    },

    open(group: db.Group): void {
      this.group = group;
      this.modal().open();
    },

    close() {
      this.modal().close();
    },

    delete() {
      if (this.group != null) {
        db.deleteGroup(this.group.id);
      }

      this.close();
    },
  },
});
