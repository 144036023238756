












import Vue, { PropType } from "vue";

import { AdminDoc } from "~/db";

export default Vue.extend({
  props: {
    admin: {
      type: Object as PropType<AdminDoc>,
      required: true,
    },
  },

  methods: {
    async delete_(): Promise<void> {
      await this.admin.ref.delete();
    },
  },
});
