


































import Vue from "vue";

import GroupCreate from "~/components/GroupCreate.vue";
import GroupDelete from "~/components/GroupDelete.vue";
import GroupRow from "~/components/GroupRow.vue";
import GroupUsers from "~/components/GroupUsers.vue";

import * as db from "~/db";

export default Vue.extend({
  name: "Groups",

  components: {
    GroupCreate,
    GroupDelete,
    GroupRow,
    GroupUsers,
  },

  data() {
    return {
      selectedGroup: null as db.Group | null,
      groups: db.getGroups(),
    };
  },

  methods: {
    select(group: db.Group) {
      this.selectedGroup = group;
    },

    create() {
      const create = this.$refs.create as InstanceType<typeof GroupCreate>;
      create.open();
    },

    delete_(group: db.Group) {
      const delete_ = this.$refs.delete as InstanceType<typeof GroupDelete>;
      delete_.open(group);
    },
  },
});
