import { io, Socket } from "socket.io-client";

import auth from "~/utils/auth";

const lookup = (roomId: string, languageCode: string, path = ""): Socket => {
  const url = process.env.SOCKET_IO_URL;

  const socket = io(`${url}/${path}`, {
    auth: async (cb) => {
      const idToken = await auth.user?.getIdToken();

      cb({
        idToken,
        roomId: roomId,
      });
    },
    query: {
      languageCode: languageCode,
    },
  });

  socket.io.on("error", (error: Error) => {
    console.error(`Error: ${error}`);
  });

  socket.io.on("reconnect", (attempt: number) => {
    console.debug(`Reconnect: ${attempt}`);
  });

  socket.io.on("reconnect_attempt", (attempt: number) => {
    console.debug(`Reconnect Attempt: ${attempt}`);
  });

  socket.io.on("reconnect_error", (error: Error) => {
    console.error(`Reconnect Error: ${error}`);
  });

  socket.on("connect", () => {
    console.debug("Connect");
  });

  socket.on("disconnect", (reason: string) => {
    console.debug(`Disonnect: ${reason}`);
  });

  socket.on("connect_error", (error: Error) => {
    console.error(`Connect Error: ${error}`);
  });

  return socket;
};

export { lookup as io, Socket };
