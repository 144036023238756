export default class AudioResampler {
  readonly sourceSampleRate: number;
  readonly targetSampleRate: number;

  private buffer = new Float32Array();

  constructor(sourceSampleRate: number, targetSampleRate: number) {
    this.sourceSampleRate = sourceSampleRate;
    this.targetSampleRate = targetSampleRate;
  }

  resample(data: Float32Array): Float32Array {
    if (this.sourceSampleRate === this.targetSampleRate) {
      return data;
    }

    let buffer: Float32Array;

    if (this.buffer.length > 0) {
      buffer = new Float32Array(this.buffer.length + data.length);
      buffer.set(this.buffer);
      buffer.set(data, this.buffer.length);
    } else {
      buffer = data;
    }

    const samplingRateRatio = this.sourceSampleRate / this.targetSampleRate;
    const outputSampleLength = Math.floor(
      (buffer.length - this.filter.length + 1) / samplingRateRatio + 1
    );
    const output = new Float32Array(outputSampleLength);

    for (let i = 0; i < output.length; i++) {
      const offset = Math.round(samplingRateRatio * i);
      let sample = 0;

      for (let j = 0; j < this.filter.length; j++) {
        sample += buffer[offset + j] * this.filter[j];
      }

      output[i] = sample;
    }

    const usedSampleLength = Math.round(samplingRateRatio * outputSampleLength);

    this.buffer = buffer.slice(usedSampleLength);

    return output;
  }

  readonly filter = [
    -0.037935, -0.00089024, 0.040173, 0.019989, 0.0047792, -0.058675, -0.056487,
    -0.0040653, 0.14527, 0.26927, 0.33913, 0.26927, 0.14527, -0.0040653,
    -0.056487, -0.058675, 0.0047792, 0.019989, 0.040173, -0.00089024, -0.037935,
  ];
}
