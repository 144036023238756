import auth from "~/utils/auth";
import firebase from "~/utils/firebase";

export * from "./admin";
export * from "./file";
export * from "./folder";
export * from "./organization";
export * from "./phrase";
export * from "./room";
export * from "./speech";
export * from "./usage";
export * from "./user";

type DocumentData = firebase.firestore.DocumentData;
type Timestamp = firebase.firestore.Timestamp;
type DocumentReference<T = DocumentData> =
  firebase.firestore.DocumentReference<T>;
type DocumentSnapshot = firebase.firestore.DocumentSnapshot;
type Query<T = DocumentData> = firebase.firestore.Query<T>;
type CollectionReference<T = DocumentData> =
  firebase.firestore.CollectionReference<T>;

const firestore = firebase.firestore();
export const now = firebase.firestore.FieldValue.serverTimestamp();
export const del = firebase.firestore.FieldValue.delete();
export const merge = { merge: true };
export const createTime = { createTime: now, updateTime: now };
export const updateTime = { updateTime: now };

// Data

class BaseDocument {
  constructor(doc: DocumentSnapshot) {
    this.id = doc.id;

    const data = doc.data() as BaseDocument;

    this.createTime = data.createTime;
    this.updateTime = data.updateTime;
  }

  readonly id: string;

  readonly createTime: Timestamp;
  readonly updateTime: Timestamp;
}

export class Profile extends BaseDocument {
  constructor(doc: DocumentSnapshot) {
    super(doc);

    const data = doc.data() as Profile;

    this.name = data.name;
  }

  readonly name: string;

  index?: number;
}

export class Group extends BaseDocument {
  constructor(doc: DocumentSnapshot) {
    super(doc);

    const data = doc.data() as Group;

    this.oid = data.oid;
    this.name = data.name;
  }

  readonly oid: string;
  readonly name: string;
}

export class GroupUser extends BaseDocument {
  constructor(doc: DocumentSnapshot) {
    super(doc);

    const data = doc.data() as GroupUser;

    this.uid = data.uid;
  }

  readonly uid: string;
}

// Collections

export const profiles = (): CollectionReference => {
  return firestore.collection("profiles");
};

export const getProfile = async (uid: string): Promise<Profile | undefined> => {
  const doc = await profiles().doc(uid).get();

  if (!doc.exists) return;

  return new Profile(doc);
};

export const getProfiles = (): Profile[] => {
  const oid = auth.oid;

  if (oid == null) return [];

  const query = profiles().where("oid", "==", oid);

  return getCollection(query, Profile);
};

// Groups

export const groups = (): CollectionReference => {
  return firestore.collection("groups");
};

export const group = (groupId: string): DocumentReference => {
  return groups().doc(groupId);
};

export const getGroups = (): Group[] => {
  const oid = auth.oid;

  if (oid == null) return [];

  const query = groups().where("oid", "==", oid);

  return getCollection(query, Group);
};

export const setGroupName = (groupId: string, name: string): Promise<void> => {
  return group(groupId).update({ name, ...updateTime });
};

export const groupUsers = (groupId: string): CollectionReference => {
  return group(groupId).collection("users");
};

export const getGroupUsers = (groupId: string): GroupUser[] => {
  const query = groupUsers(groupId);

  return getCollection(query, GroupUser);
};

export const changeGroupUsers = async (
  groupId: string,
  changes: { [key: string]: boolean }
): Promise<void> => {
  const users = groupUsers(groupId);
  const batch = firestore.batch();

  for (const uid in changes) {
    if (changes[uid]) {
      batch.set(users.doc(uid), { uid, ...createTime });
    } else {
      batch.delete(users.doc(uid));
    }
  }

  return batch.commit();
};

// Rooms

const rooms = (): CollectionReference => {
  return firestore.collection("rooms");
};

const room = (roomId: string): DocumentReference => {
  return rooms().doc(roomId);
};

// Speeches

export const speeches = (roomId: string): CollectionReference => {
  return room(roomId).collection("speeches");
};

export const speech = (roomId: string, speechId: string): DocumentReference => {
  return speeches(roomId).doc(speechId);
};

const getCollection = <T>(
  query: Query,
  type: new (doc: DocumentSnapshot) => T,
  callback?: (data: T) => void
): T[] => {
  const collection: T[] = [];

  query.onSnapshot(
    (snapshot) => {
      for (const change of snapshot.docChanges()) {
        const data = new type(change.doc);

        switch (change.type) {
          case "added": {
            collection.splice(change.newIndex, 0, data);
            break;
          }
          case "modified": {
            collection.splice(change.oldIndex, 1);
            collection.splice(change.newIndex, 0, data);
            break;
          }
          case "removed":
            collection.splice(change.oldIndex, 1);
            break;
        }

        if (callback) {
          callback(data);
        }
      }
    },
    (error) => {
      console.error(error);
    }
  );

  return collection;
};

type Document<T> = T & {
  id: string;
  ref: firebase.firestore.DocumentReference<T>;
};

export const getDocuments = <T>(query: Query<T>): Document<T>[] => {
  const documents: Document<T>[] = [];

  query.onSnapshot(
    (snapshot) => {
      for (const change of snapshot.docChanges()) {
        const doc = {
          id: change.doc.id,
          ref: change.doc.ref,
          ...change.doc.data(),
        };
        switch (change.type) {
          case "added": {
            documents.splice(change.newIndex, 0, doc);
            break;
          }
          case "modified": {
            documents.splice(change.oldIndex, 1);
            documents.splice(change.newIndex, 0, doc);
            break;
          }
          case "removed":
            documents.splice(change.oldIndex, 1);
            break;
        }
      }
    },
    (error) => {
      console.error(error);
    }
  );

  return documents;
};

// Groups

export const addGroup = (name: string): Promise<DocumentReference> => {
  return groups().add({
    oid: auth.oid,
    name,
    owner: auth.uid,
    creator: auth.uid,
    ...createTime,
  });
};

export const deleteGroup = (groupId: string): Promise<void> => {
  return groups().doc(groupId).delete();
};
