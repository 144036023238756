export interface Language {
  country: string;
  name: string;
  code: string;
  flag: string;
}

export interface Region {
  name: string;
  languages: Language[];
}

const regions: Region[] = [
  {
    name: "North America",
    languages: [
      { country: "United States", name: "English", code: "en-US", flag: "us" },
      { country: "United States", name: "Spanish", code: "es-US", flag: "us" },
      { country: "Canada", name: "English", code: "en-CA", flag: "ca" },
      { country: "Canada", name: "French", code: "fr-CA", flag: "ca" },
      { country: "Puerto Rico", name: "Spanish", code: "es-PR", flag: "pr" },
    ],
  },
  {
    name: "Asia",
    languages: [
      { country: "Japan", name: "日本語", code: "ja-JP", flag: "jp" },
      { country: "South Korea", name: "Korean", code: "ko-KR", flag: "kr" },
      {
        country: "Simplified, China",
        name: "Chinese, Mandarin",
        code: "zh",
        flag: "cn",
      },
      {
        country: "Traditional Hong Kong",
        name: "Chinese, Cantonese",
        code: "yue-Hant-HK",
        flag: "hk",
      },
      { country: "Hong Kong", name: "English", code: "en-HK", flag: "hk" },
      { country: "Bangladesh", name: "Bengali", code: "bn-BD", flag: "bd" },
      { country: "Indonesia", name: "Indonesian", code: "id-ID", flag: "id" },
      { country: "Indonesia", name: "Javanese", code: "jv-ID", flag: "id" },
      { country: "Indonesia", name: "Sundanese", code: "su-ID", flag: "id" },
      { country: "India", name: "Bengali", code: "bn-IN", flag: "in" },
      { country: "India", name: "English", code: "en-IN", flag: "in" },
      { country: "India", name: "Gujarati", code: "gu-IN", flag: "in" },
      { country: "India", name: "Hindi", code: "hi-IN", flag: "in" },
      { country: "India", name: "Kannada", code: "kn-IN", flag: "in" },
      { country: "India", name: "Malayalam", code: "ml-IN", flag: "in" },
      { country: "India", name: "Marathi", code: "mr-IN", flag: "in" },
      {
        country: "Gurmukhi India",
        name: "Punjabi",
        code: "pa-Guru-IN",
        flag: "in",
      },
      { country: "India", name: "Tamil", code: "ta-IN", flag: "in" },
      { country: "India", name: "Telugu", code: "te-IN", flag: "in" },
      { country: "India", name: "Urdu", code: "ur-IN", flag: "in" },
      { country: "Laos", name: "Lao", code: "lo-LA", flag: "la" },
      { country: "Sri Lanka", name: "Sinhala", code: "si-LK", flag: "lk" },
      { country: "Myanmar", name: "Burmese", code: "my-MM", flag: "mm" },
      { country: "Mongolia", name: "Mongolian", code: "mn-MN", flag: "mn" },
      { country: "Malaysia", name: "Malay", code: "ms-MY", flag: "my" },
      { country: "Malaysia", name: "Tamil", code: "ta-MY", flag: "my" },
      { country: "Nepal", name: "Nepali", code: "ne-NP", flag: "np" },
      { country: "Philippines", name: "English", code: "en-PH", flag: "ph" },
      { country: "Philippines", name: "Filipino", code: "fil-PH", flag: "ph" },
      { country: "Pakistan", name: "English", code: "en-PK", flag: "pk" },
      { country: "Pakistan", name: "Urdu", code: "ur-PK", flag: "pk" },
      { country: "Singapore", name: "English", code: "en-SG", flag: "sg" },
      { country: "Singapore", name: "Tamil", code: "ta-SG", flag: "sg" },
      { country: "Sri Lanka", name: "Tamil", code: "ta-LK", flag: "lk" },
      { country: "Thailand", name: "Thai", code: "th-TH", flag: "th" },
      {
        country: "Traditional, Taiwan",
        name: "Chinese, Mandarin",
        code: "zh-TW",
        flag: "tw",
      },
      { country: "Vietnam", name: "Vietnamese", code: "vi-VN", flag: "vn" },
    ],
  },
  {
    name: "Europe",
    languages: [
      { country: "United Kingdom", name: "English", code: "en-GB", flag: "gb" },
      { country: "Ireland", name: "English", code: "en-IE", flag: "ie" },
      { country: "Italy", name: "Italian", code: "it-IT", flag: "it" },
      { country: "France", name: "French", code: "fr-FR", flag: "fr" },
      { country: "Belgium", name: "Dutch", code: "nl-BE", flag: "be" },
      { country: "Belgium", name: "French", code: "fr-BE", flag: "be" },
      { country: "Bulgaria", name: "Bulgarian", code: "bg-BG", flag: "bg" },
      { country: "Switzerland", name: "French", code: "fr-CH", flag: "ch" },
      { country: "Switzerland", name: "German", code: "de-CH", flag: "ch" },
      { country: "Switzerland", name: "Italian", code: "it-CH", flag: "ch" },
      { country: "Czech Republic", name: "Czech", code: "cs-CZ", flag: "cz" },
      { country: "Germany", name: "German", code: "de-DE", flag: "de" },
      { country: "Denmark", name: "Danish", code: "da-DK", flag: "dk" },
      { country: "Albania", name: "Albanian", code: "sq-AL", flag: "al" },
      { country: "Armenia", name: "Armenian", code: "hy-AM", flag: "am" },
      { country: "Austria", name: "German", code: "de-AT", flag: "at" },
      { country: "Azerbaijan", name: "Azerbaijani", code: "az-AZ", flag: "az" },
      {
        country: "Bosnia and Herzegovina",
        name: "Bosnian",
        code: "bs-BA",
        flag: "ba",
      },
      { country: "Estonia", name: "Estonian", code: "et-EE", flag: "ee" },
      { country: "Spain", name: "Basque", code: "eu-ES", flag: "es" },
      { country: "Spain", name: "Catalan", code: "ca-ES", flag: "es" },
      { country: "Spain", name: "Galician", code: "gl-ES", flag: "es" },
      { country: "Spain", name: "Spanish", code: "es-ES", flag: "es" },
      { country: "Finland", name: "Finnish", code: "fi-FI", flag: "fi" },
      { country: "Georgia", name: "Georgian", code: "ka-GE", flag: "ge" },
      { country: "Greece", name: "Greek", code: "el-GR", flag: "gr" },
      { country: "Croatia", name: "Croatian", code: "hr-HR", flag: "hr" },
      { country: "Hungary", name: "Hungarian", code: "hu-HU", flag: "hu" },
      { country: "Iceland", name: "Icelandic", code: "is-IS", flag: "is" },
      { country: "Lithuania", name: "Lithuanian", code: "lt-LT", flag: "lt" },
      { country: "Latvia", name: "Latvian", code: "lv-LV", flag: "lv" },
      {
        country: "North Macedonia",
        name: "Macedonian",
        code: "mk-MK",
        flag: "mk",
      },
      { country: "Netherlands", name: "Dutch", code: "nl-NL", flag: "nl" },
      {
        country: "Norway",
        name: "Norwegian Bokmål",
        code: "no-NO",
        flag: "no",
      },
      { country: "Poland", name: "Polish", code: "pl-PL", flag: "pl" },
      { country: "Portugal", name: "Portuguese", code: "pt-PT", flag: "pt" },
      { country: "Romania", name: "Romanian", code: "ro-RO", flag: "ro" },
      { country: "Serbia", name: "Serbian", code: "sr-RS", flag: "rs" },
      { country: "Russia", name: "Russian", code: "ru-RU", flag: "ru" },
      { country: "Sweden", name: "Swedish", code: "sv-SE", flag: "se" },
      { country: "Slovenia", name: "Slovenian", code: "sl-SI", flag: "si" },
      { country: "Slovakia", name: "Slovak", code: "sk-SK", flag: "sk" },
      { country: "Ukraine", name: "Ukrainian", code: "uk-UA", flag: "ua" },
      { country: "Uzbekistan", name: "Uzbek", code: "uz-UZ", flag: "uz" },
    ],
  },
  {
    name: "Oceania",
    languages: [
      { country: "Australia", name: "English", code: "en-AU", flag: "au" },
      { country: "New Zealand", name: "English", code: "en-NZ", flag: "nz" },
    ],
  },
  {
    name: "Latin America",
    languages: [
      { country: "Argentina", name: "Spanish", code: "es-AR", flag: "ar" },
      { country: "Bolivia", name: "Spanish", code: "es-BO", flag: "bo" },
      { country: "Brazil", name: "Portuguese", code: "pt-BR", flag: "br" },
      { country: "Chile", name: "Spanish", code: "es-CL", flag: "cl" },
      { country: "Colombia", name: "Spanish", code: "es-CO", flag: "co" },
      { country: "Costa Rica", name: "Spanish", code: "es-CR", flag: "cr" },
      {
        country: "Dominican Republic",
        name: "Spanish",
        code: "es-DO",
        flag: "do",
      },
      { country: "Ecuador", name: "Spanish", code: "es-EC", flag: "ec" },
      { country: "Guatemala", name: "Spanish", code: "es-GT", flag: "gt" },
      { country: "Honduras", name: "Spanish", code: "es-HN", flag: "hn" },
      { country: "Mexico", name: "Spanish", code: "es-MX", flag: "mx" },
      { country: "Panama", name: "Spanish", code: "es-PA", flag: "pa" },
      { country: "Peru", name: "Spanish", code: "es-PE", flag: "pe" },
      { country: "Paraguay", name: "Spanish", code: "es-PY", flag: "py" },
      { country: "Uruguay", name: "Spanish", code: "es-UY", flag: "uy" },
      { country: "Venezuela", name: "Spanish", code: "es-VE", flag: "ve" },
    ],
  },
  {
    name: "Middle East",
    languages: [
      {
        country: "United Arab Emirates",
        name: "Arabic",
        code: "ar-AE",
        flag: "ae",
      },
      { country: "Bahrain", name: "Arabic", code: "ar-BH", flag: "bh" },
      { country: "Israel", name: "Arabic", code: "ar-IL", flag: "il" },
      { country: "Israel", name: "Hebrew", code: "iw-IL", flag: "il" },
      { country: "Iraq", name: "Arabic", code: "ar-IQ", flag: "iq" },
      { country: "Iran", name: "Persian", code: "fa-IR", flag: "ir" },
      { country: "Jordan", name: "Arabic", code: "ar-JO", flag: "jo" },
      { country: "Kuwait", name: "Arabic", code: "ar-KW", flag: "kw" },
      { country: "Lebanon", name: "Arabic", code: "ar-LB", flag: "lb" },
      { country: "Nicaragua", name: "Spanish", code: "es-NI", flag: "ni" },
      { country: "Oman", name: "Arabic", code: "ar-OM", flag: "om" },
      {
        country: "State of Palestine",
        name: "Arabic",
        code: "ar-PS",
        flag: "ps",
      },
      { country: "Qatar", name: "Arabic", code: "ar-QA", flag: "qa" },
      { country: "Saudi Arabia", name: "Arabic", code: "ar-SA", flag: "sa" },
      { country: "El Salvador", name: "Spanish", code: "es-SV", flag: "sv" },
      { country: "Turkey", name: "Turkish", code: "tr-TR", flag: "tr" },
      { country: "Yemen", name: "Arabic", code: "ar-YE", flag: "ye" },
    ],
  },
  {
    name: "Africa",
    languages: [
      { country: "Algeria", name: "Arabic", code: "ar-DZ", flag: "dz" },
      { country: "Egypt", name: "Arabic", code: "ar-EG", flag: "eg" },
      { country: "Ethiopia", name: "Amharic", code: "am-ET", flag: "et" },
      { country: "Ghana", name: "English", code: "en-GH", flag: "gh" },
      { country: "Kenya", name: "English", code: "en-KE", flag: "ke" },
      { country: "Kenya", name: "Swahili", code: "sw-KE", flag: "ke" },
      { country: "Cambodia", name: "Khmer", code: "km-KH", flag: "kh" },
      { country: "Morocco", name: "Arabic", code: "ar-MA", flag: "ma" },
      { country: "Nigeria", name: "English", code: "en-NG", flag: "ng" },
      { country: "Tunisia", name: "Arabic", code: "ar-TN", flag: "tn" },
      { country: "Tanzania", name: "English", code: "en-TZ", flag: "tz" },
      { country: "Tanzania", name: "Swahili", code: "sw-TZ", flag: "tz" },
      { country: "South Africa", name: "Afrikaans", code: "af-ZA", flag: "za" },
      { country: "South Africa", name: "English", code: "en-ZA", flag: "za" },
      { country: "South Africa", name: "Zulu", code: "zu-ZA", flag: "za" },
    ],
  },
];

export default regions;
