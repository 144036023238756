import { GetterTree, ActionTree, MutationTree } from "vuex";

import * as db from "~/db";
import firebase from "~/utils/firebase";

// TODO import from ../state.ts
const rootState = {};

export interface State {
  initialize: Promise<void>;
  user?: firebase.User;
  claims?: Claims;
  name?: string;
  languageCode: string;
  minLineLength: number;
  maxLineLength: number;
}

interface User {
  auth: Auth;
  name?: string;
}

interface Auth {
  user?: firebase.User;
  claims?: Claims;
}

interface Claims {
  oid?: string;
  admin?: boolean;
  owner?: boolean;
}

export const namespaced = true;

let initializeResolve: () => void;

export const state: State = {
  initialize: new Promise((resolve) => {
    initializeResolve = resolve;
  }),
  user: undefined,
  claims: undefined,
  name: sessionStorage.getItem("name") || undefined,
  languageCode: localStorage.getItem("languageCode") || "ja-JP",
  minLineLength: Number(localStorage.getItem("minLineLength") ?? 10),
  maxLineLength: Number(localStorage.getItem("maxLineLength") ?? 100),
};

export const getters: GetterTree<State, typeof rootState> = {
  signedIn: (state) => {
    return state.user != null;
  },
  anonymous: (state) => {
    return state.user?.isAnonymous === true;
  },
  uid: (state) => {
    return state.user?.uid;
  },
  oid: (state) => {
    return state.claims?.oid;
  },
  admin: (state) => {
    return state.claims?.admin === true;
  },
  owner: (state) => {
    return state.claims?.owner === true;
  },
};

export const actions: ActionTree<State, typeof rootState> = {
  auth: async ({ commit }, auth: Auth) => {
    let name: string | undefined;

    if (auth.user?.uid != null) {
      const profile = await db.getProfile(auth.user?.uid);
      name = profile?.name;
    }

    commit("user", { auth, name });
  },
};

export const mutations: MutationTree<State> = {
  user: async (state, user: User): Promise<void> => {
    state.user = user.auth.user;
    state.claims = user.auth.claims;
    state.name = user.name || sessionStorage.getItem("name") || undefined;

    initializeResolve();
  },

  name: (state, name) => {
    if (state.user == null) {
      state.name = name;
      sessionStorage.setItem("name", name);
    }
  },

  updateName: (state, name) => {
    state.name = name;
    sessionStorage.setItem("name", name);
  },

  languageCode: (state, languageCode) => {
    state.languageCode = languageCode;
    localStorage.setItem("languageCode", languageCode);
  },

  minLineLength: (state, minLineLength) => {
    minLineLength = Number(minLineLength);

    if (minLineLength >= 0) {
      state.minLineLength = minLineLength;
      localStorage.setItem("minLineLength", String(minLineLength));
    }
  },

  maxLineLength: (state, maxLineLength) => {
    maxLineLength = Number(maxLineLength);

    if (maxLineLength >= 0) {
      state.maxLineLength = maxLineLength;
      localStorage.setItem("maxLineLength", String(maxLineLength));
    }
  },
};
