import firebase, { functions } from "~/utils/firebase";

import { BaseDocument } from "./document";

interface UserData {
  name: string;
  email: string;
  oid?: string;
  owner?: boolean;
  lastSignInTime?: firebase.firestore.Timestamp;

  createTime: firebase.firestore.Timestamp;
  updateTime: firebase.firestore.Timestamp;
}

const dataConverter: firebase.firestore.FirestoreDataConverter<UserData> = {
  toFirestore(user: UserData) {
    const { name, email, oid, owner, lastSignInTime, createTime, updateTime } =
      user;

    return { name, email, oid, owner, lastSignInTime, createTime, updateTime };
  },

  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ) {
    const data = snapshot.data(options);
    const { name, email, oid, owner, lastSignInTime, createTime, updateTime } =
      {
        ...{
          name: "",
          email: "",
          createTime: new firebase.firestore.Timestamp(0, 0),
          updateTime: new firebase.firestore.Timestamp(0, 0),
        },
        ...(data as Partial<UserData>),
      };

    return { name, email, oid, owner, lastSignInTime, createTime, updateTime };
  },
};

export class UserDoc extends BaseDocument<UserData> {
  static collection(): firebase.firestore.CollectionReference<UserData> {
    return firebase
      .firestore()
      .collection("users")
      .withConverter(dataConverter);
  }

  static listenUsers(oid: string): UserDoc[] {
    const query = this.collection().where("oid", "==", oid);
    return this.listenDocuments(query);
  }

  static async listenUser(uid: string): Promise<UserDoc | undefined> {
    const ref = this.collection().doc(uid);

    return this.listenDocument(ref);
  }

  owner(assign: boolean): Promise<void> {
    const owner = assign || firebase.firestore.FieldValue.delete();
    return this.update({ owner });
  }

  remove(): Promise<void> {
    return this.update({ oid: firebase.firestore.FieldValue.delete() });
  }
}

export interface CreateUsersResult {
  email: string;
  name: string;
  error?: Error;
}

export async function createUsers(data: {
  params: { email: string; name: string }[];
  oid: string;
  from: string;
}): Promise<CreateUsersResult[]> {
  const result = await functions.httpsCallable("createUsers")(data);
  return result.data;
}
