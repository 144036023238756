








































































































import $ from "~/utils/jquery-loader";
import Vue from "vue";

export default Vue.extend({
  name: "Subscribe",

  data() {
    return {
      company: "",
      department: "",
      name: "",
      email: "",
    };
  },

  created() {
    console.debug("at created!");

    // 下記は data(){} の外に出すと動かない。なぜか理解する。
    $(function () {
      // 「利用規約」を ui modal で表示
      $("#a_toa").click(function () {
        $(".toa").modal("show");
      });
      $(".toa").modal({
        closable: true,
      });
      // 外部HTMLファイルがうまく表示できない（localhostだから？）
      $(".toa").ready(function () {
        $("#toa_content").load("../assets/terms.html");
      });
      // ui form のバリデーション
      $(".ui.form").form({
        // fields: {
        //   company: { rules: [{ type: "empty" }] },
        //   department: { rules: [{ type: "empty" }] },
        //   name: { rules: [{ type: "empty" }] },
        //   email: { rules: [{ type: "empty" }] },
        //   Terms: {
        //     rules: [
        //       { type: "checked", prompt: "確認してチェックして下さい。" },
        //     ],
        //   },
        // },
        onSuccess: function () {
          $("#warn")[0].className = "ui compact hidden message";
          return false;
        },
        onFailure: function () {
          $("#warn")[0].className = "ui compact message";
          const errorFields = $(".field.error input,.field.error select", this);
          errorFields.first().focus();
          return false;
        },
      });
      // 下記はラベル部分クリックできるようにする為のコードです。
      $(".ui.checkbox").checkbox();
    });
  },

  methods: {
    submitSubscribe(): void {
      console.debug(
        "fieldの値は、" +
          this.company +
          this.department +
          this.name +
          this.email
      );
    },
  },
});
