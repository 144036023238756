import firebase from "~/utils/firebase";
import { UserDoc } from "~/db";

interface Claims {
  oid?: string;
  admin?: boolean;
  owner?: boolean;
  gid?: string[];
}

class Auth {
  user?: firebase.User;
  claims?: Claims;

  get uid(): string | undefined {
    return this.user?.uid;
  }

  get email(): string | null | undefined {
    return this.user?.email;
  }

  get oid(): string | undefined {
    return this.claims?.oid;
  }

  get admin(): boolean | undefined {
    return this.claims?.admin;
  }

  get owner(): boolean | undefined {
    return this.claims?.owner;
  }

  get gid(): string[] | undefined {
    return this.claims?.gid;
  }

  private observers: (() => void)[] = [];

  constructor() {
    let unsubscribe: (() => void) | null;

    firebase.auth().onAuthStateChanged(async (user) => {
      if (unsubscribe != null) {
        unsubscribe();
        unsubscribe = null;
      }

      this.user = user || undefined;
      this.claims = (await user?.getIdTokenResult())?.claims;

      if (user) {
        let first = true;

        unsubscribe = firebase
          .firestore()
          .collection("claims")
          .doc(user.uid)
          .onSnapshot(
            async () => {
              if (first) {
                first = false;
                return;
              }

              this.claims = (await user.getIdTokenResult(true)).claims;
              console.debug(this.claims);

              this.notify();
            },
            (error) => console.error(error)
          );

        console.debug(this.claims);

        if (user.metadata.lastSignInTime != null) {
          const lastSignInTime = new Date(user.metadata.lastSignInTime);
          UserDoc.collection().doc(user.uid).update({ lastSignInTime });
        }
      }

      this.notify();
    });
  }

  observe(observer: () => void): () => void {
    this.observers.push(observer);

    return (): void => {
      this.observers = this.observers.filter((value) => value !== observer);
    };
  }

  notify(): void {
    for (const observer of this.observers) {
      observer();
    }
  }

  isLink(): boolean {
    return firebase.auth().isSignInWithEmailLink(location.href);
  }

  async sendLink(email: string, url?: string): Promise<void> {
    const actionCodeSettings = {
      url: url || `${location.origin}/signin`,
      handleCodeInApp: true,
    };

    await firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings);
  }

  async signInWithLink(email: string, link: string): Promise<void> {
    if (this.email != email) {
      await firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.LOCAL);
      await firebase.auth().signInWithEmailLink(email, link);
    } else {
      const credential = firebase.auth.EmailAuthProvider.credentialWithLink(
        email,
        link
      );
      await this.user?.reauthenticateWithCredential(credential);
    }
  }

  async signInWithPassword(email: string, password: string): Promise<void> {
    if (this.email != email) {
      await firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.LOCAL);
      await firebase.auth().signInWithEmailAndPassword(email, password);
    } else {
      const credential = firebase.auth.EmailAuthProvider.credential(
        email,
        password
      );
      await this.user?.reauthenticateWithCredential(credential);
    }
  }

  async signInAnonymously(): Promise<firebase.auth.UserCredential> {
    await firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.SESSION);
    return await firebase.auth().signInAnonymously();
  }

  async signOut(): Promise<void> {
    await firebase.auth().signOut();
  }

  async updatePassword(password: string): Promise<void> {
    if (this.user?.email == null) return;

    await this.user.updatePassword(password);
  }
}

export default new Auth();
