































































import Vue, { PropType } from "vue";

import PhraseCreate from "~/components/PhraseCreate.vue";
import ProgressBar from "~/components/ProgressBar.vue";
import SpeechRow from "~/components/SpeechRow.vue";
import { FileDoc, RoomDoc, SpeechDoc } from "~/db";
import firebase from "~/utils/firebase";

export default Vue.extend({
  components: {
    PhraseCreate,
    ProgressBar,
    SpeechRow,
  },

  props: {
    room: {
      type: Object as PropType<RoomDoc>,
      required: true,
    },
    lines: {
      type: Array as PropType<SpeechDoc[][]>,
      required: true,
    },
    profiles: {
      type: Object as PropType<{ [uid: string]: Profile | undefined }>,
      required: true,
    },
    live: {
      type: Boolean,
      default: false,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    showSpeaker: {
      type: Boolean,
      default: false,
    },
    fontSize: {
      type: Number,
      default: 1,
    },
    darkMode: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return new (class {
      audio?: HTMLAudioElement;
    })();
  },

  computed: {
    empty(): boolean {
      return this.lines.length === 0;
    },

    loading(): boolean {
      return this.room.data.progress !== undefined;
    },

    tableStyle(): { fontSize: string } | false {
      return this.fullscreen && { fontSize: `${this.fontSize}em` };
    },

    start(): number | null {
      return this.live ? null : this.room.data.createTime.seconds;
    },
  },

  methods: {
    async onFocus(speech: SpeechDoc) {
      const path = `rooms/${this.room.id}/speeches/${speech.id}.mp3`;
      const ref = firebase.storage().ref(path);
      const url = await ref.getDownloadURL().catch(() => undefined);

      if (url == null) {
        this.audio?.pause();
        this.audio = undefined;
        return;
      }

      if (this.audio?.src !== url) {
        this.audio?.pause();
        this.audio = new Audio(url);
      }

      this.audio?.play();
    },

    onBlur() {
      this.audio?.pause();
    },

    onEnter(row: InstanceType<typeof SpeechRow>) {
      const rows = this.$refs.row as InstanceType<typeof SpeechRow>[];
      const index = rows.indexOf(row);

      if (0 <= index && index < rows.length - 1) {
        rows[index + 1].focus();
      }
    },

    onPhrase(speeches: SpeechDoc[]) {
      (this.$refs.phrase as InstanceType<typeof PhraseCreate>).open(speeches);
    },

    cancel() {
      FileDoc.cancel(this.room.id);
    },
  },
});
