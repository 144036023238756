import firebase from "~/utils/firebase";

import { BaseDocument } from "./document";

export interface UsageData {
  limit: number;
  amount: number;

  createTime: firebase.firestore.Timestamp;
  updateTime: firebase.firestore.Timestamp;
}

const dataConverter: firebase.firestore.FirestoreDataConverter<UsageData> = {
  toFirestore(usage: Partial<UsageData>) {
    const { limit, amount, createTime, updateTime } = usage;
    return { limit, amount, createTime, updateTime };
  },

  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options?: firebase.firestore.SnapshotOptions
  ) {
    const data = snapshot.data(options);
    const epoch = new firebase.firestore.Timestamp(0, 0);
    const { limit, amount, createTime, updateTime } = {
      ...{
        limit: 0,
        amount: 0,
        createTime: epoch,
        updateTime: epoch,
      },
      ...(data as Partial<UsageData>),
    };

    return { limit, amount, createTime, updateTime };
  },
};

export class UsageDoc extends BaseDocument<UsageData> {
  static collection(
    organizationId?: string
  ): firebase.firestore.Query<UsageData> {
    if (organizationId) {
      return firebase
        .firestore()
        .collection("organizations")
        .doc(organizationId)
        .collection("usages")
        .withConverter(dataConverter);
    }

    return firebase
      .firestore()
      .collection("organizations")
      .firestore.collectionGroup("usages")
      .withConverter(dataConverter);
  }

  static listenUsages(organizationId?: string): UsageDoc[] {
    if (organizationId) {
      return this.listenDocuments(this.collection(organizationId));
    }

    return this.listenDocuments(this.collection());
  }
}
