import firebase from "~/utils/firebase";

export const localeDateTime = (
  timestamp?: firebase.firestore.Timestamp
): string => {
  if (timestamp == null) {
    return "";
  }

  const date = timestamp.toDate();
  const options: Intl.DateTimeFormatOptions = {
    hour: "2-digit",
    minute: "2-digit",
  };
  const time = date.toLocaleTimeString([], options);

  return `${date.toLocaleDateString()} ${time}`;
};

export const localeTime = (
  timestamp?: firebase.firestore.Timestamp
): string => {
  if (timestamp == null) {
    return "";
  }

  const date = timestamp.toDate();

  return `${date.toLocaleTimeString()}`;
};

export const durationH = (time: number): string => {
  const hours = Math.trunc(time / 60 / 60);
  return `${hours}`;
};

export const durationHMM = (time: number): string => {
  const hours = Math.trunc(time / 60 / 60);
  const minutes = String(Math.trunc((time / 60) % 60)).padStart(2, "0");
  return `${hours}:${minutes}`;
};

export const durationHMMSS = (time: number): string => {
  const hours = Math.trunc(time / 60 / 60);
  const minutes = String(Math.trunc((time / 60) % 60)).padStart(2, "0");
  const seconds = String(Math.trunc(time % 60)).padStart(2, "0");
  return `${hours}:${minutes}:${seconds}`;
};

export const durationHMMSSJP = (time: number): string => {
  const hours = Math.trunc(time / 60 / 60);
  const minutes = String(Math.trunc((time / 60) % 60)).padStart(2, "0");
  const seconds = String(Math.trunc(time % 60)).padStart(2, "0");
  return `${hours}時間${minutes}分${seconds}秒`;
};
