

















































import Vue from "vue";
import { mapState } from "vuex";
import parse from "csv-parse/lib/sync";

import ErrorMessage from "~/components/ErrorMessage.vue";
import FileInput from "~/components/FileInput.vue";
import Modal from "~/components/Modal.vue";
import { createUsers, CreateUsersResult } from "~/db/user";

export default Vue.extend({
  components: { ErrorMessage, FileInput, Modal },

  props: {
    oid: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      loading: false,
      error: undefined as Error | undefined,
      results: undefined as CreateUsersResult[] | undefined,
    };
  },

  computed: {
    formClass(): unknown {
      return {
        loading: this.loading,
      };
    },

    sample(): string {
      const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
      const csv = `name,email\n山田太郎,t-yamada@example.com\n山田花子,h-yamada@example.com`;
      const blob = new Blob([bom, csv], { type: "text/csv" });

      return URL.createObjectURL(blob);
    },

    success(): number | undefined {
      return this.results?.filter((result) => result.error == null).length;
    },

    errors(): CreateUsersResult[] | undefined {
      return this.results?.filter((result) => result.error != null);
    },

    complete(): boolean {
      return this.error != null || this.results != null;
    },

    ...mapState("user", { from: "name" }),
  },

  methods: {
    modal(): InstanceType<typeof Modal> {
      return this.$refs.modal as InstanceType<typeof Modal>;
    },

    open(): void {
      this.modal().open();
    },

    close(): void {
      this.modal().close();
    },

    async upload(event: Event) {
      const input = event.target as HTMLInputElement | null;
      const files = input?.files;
      const file = files?.[0];

      if (input != null) {
        input.value = "";
      }

      if (file == null) return;

      this.loading = true;

      try {
        const text = await file.text();
        const params = parse(text, { columns: true });

        this.results = await createUsers({
          params,
          oid: this.oid,
          from: this.from,
        }).catch(() => []);

        console.log(this.results);
      } catch (error) {
        this.error = error;
      }

      this.loading = false;
    },

    closed(): void {
      this.loading = false;
      this.error = undefined;
      this.results = undefined;
    },
  },
});
