






















import Vue from "vue";

import { PhraseDoc } from "~/db";
import auth from "~/utils/auth";
import { functions } from "~/utils/firebase";

export default Vue.extend({
  name: "Development",

  methods: {
    async call(name: string) {
      try {
        await functions.httpsCallable(name)();
      } catch (error) {
        console.error(error);
      }
    },

    async pastePhrases() {
      if (auth.oid == null || auth.uid == null) return;

      const text = await navigator.clipboard.readText();
      const phrases = text.split("\n");

      for (const phrase of phrases) {
        const [spoken, transcript] = phrase.split("\t");
        PhraseDoc.addPhrase({
          spoken,
          transcript,
          oid: auth.oid,
          uid: auth.uid,
        });
      }
    },
  },
});
