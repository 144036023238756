



























































import Vue, { PropType } from "vue";
import { mapGetters, mapState } from "vuex";

import SpeechText from "~/components/SpeechText.vue";
import { localeTime, durationHMMSS } from "~/filters/time";
import { user } from "~/filters/user";
import { SpeechDoc } from "~/db";
import firebase from "~/utils/firebase";

export default Vue.extend({
  components: { SpeechText },

  filters: { localeTime, durationHMMSS, user },

  props: {
    roomId: {
      type: String,
      required: true,
    },
    speeches: {
      type: Array as PropType<SpeechDoc[]>,
      required: true,
    },
    profile: {
      type: Object as PropType<Profile>,
      default: undefined,
    },
    start: {
      type: Number,
      default: null,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    showSpeaker: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    speech(): SpeechDoc {
      return this.speeches[0];
    },

    userLanguage(): string {
      return this.languageCode.split("-")[0];
    },

    ownLanguageSpeeches(): SpeechDoc[] {
      return this.speeches.filter((speech) => {
        return (
          speech.data.languageCode.split("-")[0] === this.userLanguage ||
          speech.data.translation?.[this.userLanguage] != null
        );
      });
    },

    otherLanguageSpeeches(): SpeechDoc[] {
      return this.speeches.filter((speech) => {
        return (
          speech.data.languageCode.split("-")[0] !== this.userLanguage &&
          speech.data.transcript
        );
      });
    },

    time(): string {
      if (this.start == null) {
        return localeTime(this.speech.data.createTime);
      }

      return durationHMMSS(this.speech.data.createTime.seconds - this.start);
    },

    ...mapState("user", ["languageCode"]),
    ...mapGetters("user", ["anonymous"]),
  },

  methods: {
    onEnter(text: InstanceType<typeof SpeechText>) {
      const texts = this.$refs.text as InstanceType<typeof SpeechText>[];
      const index = texts.indexOf(text);

      if (0 <= index && index < texts.length - 1) {
        texts[index + 1].focus();
      } else {
        this.$emit("enter", this);
      }
    },

    focus() {
      const texts = this.$refs.text as InstanceType<typeof SpeechText>[];

      texts[0].focus();
      this.$el.scrollIntoView({ block: "center" });
    },

    mark() {
      const mark = this.speech.data.mark !== true;
      this.speech.update({ mark });
    },

    phrase() {
      this.$emit("phrase", this.speeches);
    },

    delete() {
      for (const speech of this.speeches) {
        speech.delete();
      }
    },
  },
});
