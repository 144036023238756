import firebase from "~/utils/firebase";

import { BaseDocument } from "./document";

interface RoomData {
  name: string;
  parent: string | null;
  oid: string;
  owner: string;
  creator: string;
  grantees: string[];
  visibility?: string;
  type: "microphone" | "file";
  progress?: number | null;
  amount: number;
  startTime?: firebase.firestore.Timestamp;
  endTime?: firebase.firestore.Timestamp;

  createTime: firebase.firestore.Timestamp;
  updateTime: firebase.firestore.Timestamp;
}

const dataConverter: firebase.firestore.FirestoreDataConverter<RoomData> = {
  toFirestore(room: RoomData) {
    const {
      name,
      parent,
      oid,
      owner,
      creator,
      grantees,
      visibility,
      type,
      progress,
      amount,
      startTime,
      endTime,
      createTime,
      updateTime,
    } = room;

    return {
      name,
      parent,
      oid,
      owner,
      creator,
      grantees,
      visibility,
      type,
      progress,
      amount,
      startTime,
      endTime,
      createTime,
      updateTime,
    };
  },

  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ) {
    const data = snapshot.data(options);
    const {
      name,
      parent,
      oid,
      owner,
      creator,
      grantees,
      visibility,
      type,
      progress,
      amount,
      startTime,
      endTime,
      createTime,
      updateTime,
    } = {
      ...{
        name: "",
        parent: null,
        oid: "",
        owner: "",
        creator: "",
        grantees: [""],
        type: "microphone" as "microphone" | "file",
        amount: 0,
        createTime: new firebase.firestore.Timestamp(0, 0),
        updateTime: new firebase.firestore.Timestamp(0, 0),
      },
      ...(data as Partial<RoomData>),
    };

    return {
      name,
      parent,
      oid,
      owner,
      creator,
      grantees,
      visibility,
      type,
      progress,
      amount,
      startTime,
      endTime,
      createTime,
      updateTime,
    };
  },
};

export class RoomDoc extends BaseDocument<RoomData> {
  static collection(): firebase.firestore.CollectionReference<RoomData> {
    return firebase
      .firestore()
      .collection("rooms")
      .withConverter(dataConverter);
  }

  static async addRoom(params: {
    name: string;
    parent: string | null;
    oid: string;
    uid: string;
    type: "microphone" | "file";
  }): Promise<string> {
    return this.add(this.collection(), {
      name: params.name,
      parent: params.parent,
      oid: params.oid,
      owner: params.uid,
      creator: params.uid,
      grantees: [""],
      type: params.type,
      amount: 0,
    });
  }

  static async listenRoom(roomId: string): Promise<RoomDoc | undefined> {
    const ref = this.collection().doc(roomId);

    return this.listenDocument(ref);
  }

  static listenRooms(params: {
    parent: string | null;
    oid: string;
    owner?: boolean;
    uid?: string;
    gid?: string[];
  }): RoomDoc[] {
    let query = this.collection()
      .where("parent", "==", params.parent)
      .where("oid", "==", params.oid);

    if (!params.owner) {
      const grantees = [""];

      if (params.uid != null) {
        grantees.push(params.uid);
      }

      if (params.gid != null) {
        grantees.push(...params.gid);
      }

      query = query.where("grantees", "array-contains-any", grantees);
    }

    return this.listenDocuments(query);
  }

  static deleteRoom(roomId: string) {
    return this.collection().doc(roomId).delete();
  }
}
