import { saveAs } from "file-saver";

interface Subtitle {
  startTime: Date;
  duration: number;
  text: string;
}

export default class Subtitles {
  private baseTime: Date;
  private data: Subtitle[] = [];

  constructor(baseTime: Date) {
    this.baseTime = baseTime;
  }

  add(startTime: Date, duration: number, text: string): void {
    this.data.push({ startTime, duration, text });
  }

  saveToSubRip(title: string): void {
    const formatTime = (time: number): string => {
      const hours = this.formatNumber(time / 1000 / 60 / 60);
      const minutes = this.formatNumber((time / 1000 / 60) % 60);
      const seconds = this.formatNumber((time / 1000) % 60);
      const milliseconds = this.formatNumber(time % 1000, 3);

      return `${hours}:${minutes}:${seconds},${milliseconds}`;
    };

    const data = this.data
      .map((subtitle, index) => {
        const startTime =
          subtitle.startTime.getTime() - this.baseTime.getTime();
        const start = formatTime(startTime);
        const end = formatTime(startTime + subtitle.duration);

        return `${index + 1}\n${start} --> ${end}\n${subtitle.text}`;
      })
      .join("\n\n");

    const blob = new Blob([data], { type: "text/plain" });

    saveAs(blob, `${title}.srt`);
  }

  saveToSubViewer(title: string): void {
    const formatTime = (time: number): string => {
      const hours = this.formatNumber(time / 1000 / 60 / 60, 1);
      const minutes = this.formatNumber((time / 1000 / 60) % 60);
      const seconds = this.formatNumber((time / 1000) % 60);
      const milliseconds = this.formatNumber(time % 1000, 3);

      return `${hours}:${minutes}:${seconds}.${milliseconds}`;
    };

    const data = this.data
      .map((subtitle) => {
        const startTime =
          subtitle.startTime.getTime() - this.baseTime.getTime();
        const start = formatTime(startTime);
        const end = formatTime(startTime + subtitle.duration);

        return `${start},${end}\n${subtitle.text}`;
      })
      .join("\n\n");

    const blob = new Blob([data], { type: "text/plain" });

    saveAs(blob, `${title}.sbv`);
  }

  private formatNumber(time: number, length = 2): string {
    return String(Math.trunc(time)).padStart(length, "0");
  }
}
