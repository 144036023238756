import firebase from "~/utils/firebase";

interface TimestampedData {
  createTime: firebase.firestore.Timestamp;
  updateTime: firebase.firestore.Timestamp;
}

type DocumentData<T> = {
  [P in keyof T]: T[P] | firebase.firestore.FieldValue;
};

type UpdateData<T> = Partial<DocumentData<T>>;
type SetData<T> = DocumentData<
  Omit<T, keyof TimestampedData> & Partial<TimestampedData>
>;

export abstract class BaseDocument<T> {
  constructor(
    readonly ref: firebase.firestore.DocumentReference<T>,
    readonly data: T
  ) {}

  get id(): string {
    return this.ref.id;
  }

  private static readonly unsubscribes = new Map<unknown, () => void>();

  static async getDocument<T, U>(
    this: {
      new (ref: firebase.firestore.DocumentReference<T>, data: T): U;
    },
    ref: firebase.firestore.DocumentReference<T>
  ): Promise<U | undefined> {
    const doc = await ref.get();
    const data = doc.data();

    if (data == null) {
      return undefined;
    }

    return new this(ref, data);
  }

  static listenDocument<T, U>(
    this: {
      new (ref: firebase.firestore.DocumentReference<T>, data: T): U;
    },
    ref: firebase.firestore.DocumentReference<T>
  ): Promise<U | undefined> {
    return new Promise((resolve, reject) => {
      let doc: U | undefined;

      const unsubscribe = ref.onSnapshot(
        (snapshot) => {
          const data = snapshot.data();

          if (doc != null) {
            Object.assign(doc, { data });
            return;
          }

          if (data != null) {
            doc = new this(ref, data);
            BaseDocument.unsubscribes.set(doc, unsubscribe);
          }

          resolve(doc);
        },
        (error) => {
          unsubscribe();
          reject(error);
        }
      );
    });
  }

  static listenDocuments<T, U>(
    this: {
      new (ref: firebase.firestore.DocumentReference<T>, data: T): U;
    },
    query: firebase.firestore.Query<T>,
    callback?: (doc: U) => void
  ): U[] {
    const documents: U[] = [];

    const unsubscribe = query.onSnapshot((snapshot) => {
      for (const change of snapshot.docChanges()) {
        const doc = new this(change.doc.ref, change.doc.data());

        switch (change.type) {
          case "added": {
            documents.splice(change.newIndex, 0, doc);
            break;
          }
          case "modified": {
            documents.splice(change.oldIndex, 1);
            documents.splice(change.newIndex, 0, doc);
            break;
          }
          case "removed":
            documents.splice(change.oldIndex, 1);
            break;
        }

        if (callback) {
          callback(doc);
        }
      }
    });

    BaseDocument.unsubscribes.set(documents, unsubscribe);

    return documents;
  }

  static unsubscribe<T>(doc?: BaseDocument<T> | BaseDocument<T>[]): void {
    this.unsubscribes.get(doc)?.();
    this.unsubscribes.delete(doc);
  }

  static async add<T>(
    collection: firebase.firestore.CollectionReference<T>,
    data: SetData<T>
  ): Promise<string> {
    const createTime = firebase.firestore.FieldValue.serverTimestamp();
    const updateTime = firebase.firestore.FieldValue.serverTimestamp();
    const docData: SetData<T> = { createTime, updateTime, ...data };
    const ref = await collection.add(docData as T);

    return ref.id;
  }

  merge(data: UpdateData<T>): Promise<void> {
    const updateTime = firebase.firestore.FieldValue.serverTimestamp();
    const docData = { updateTime, ...data };

    return this.ref.set(docData as Partial<T>, { merge: true });
  }

  set(data: SetData<T>): Promise<void> {
    const updateTime = firebase.firestore.FieldValue.serverTimestamp();
    const docData: SetData<T> = { updateTime, ...data };

    return this.ref.set(docData as T);
  }

  update(data: UpdateData<T>): Promise<void> {
    const updateTime = firebase.firestore.FieldValue.serverTimestamp();
    const docData: UpdateData<T> = { updateTime, ...data };

    return this.ref.update(docData);
  }

  delete(): Promise<void> {
    return this.ref.delete();
  }
}
