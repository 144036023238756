




















import Vue from "vue";

export default Vue.extend({
  model: {
    event: "change",
  },

  props: {
    editable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      password: "",
      previousPassowrd: "",
      showPassword: false,
      isHoverOnEye: false,
      onEnter: false,
    };
  },

  computed: {
    contenteditable(): boolean {
      return this.editable;
    },

    size(): number {
      return this.password.length + 3;
    },

    type(): string {
      return this.showPassword ? "text" : "password";
    },

    eye(): string {
      return this.showPassword
        ? "eye slash outline icon link"
        : "eye outline icon link";
    },
  },

  methods: {
    focus() {
      const element = this.$refs.password as HTMLElement;
      const range = document.createRange();
      const selection = getSelection();

      element.focus();
      range.selectNodeContents(element);
      selection?.removeAllRanges();
      selection?.addRange(range);
    },

    onKeyDown(event: KeyboardEvent) {
      const element = event.srcElement as HTMLElement;

      switch (event.keyCode) {
        case 13: // Enter
          this.$emit("enter");
          this.onEnter = true;
          break;
        case 27: // Escape
          this.password = this.previousPassowrd;
          break;
        default:
          return;
      }

      event.preventDefault();
      element.blur();
    },

    onBlur() {
      // 目のアイコンにカーソルがのっている間は、イベントを無効化する
      // エンターキーの時は判定しない
      if (this.isHoverOnEye && !this.onEnter) {
        return;
      }
      this.onEnter = false;

      const text = this.password;

      if (!text?.trim().length) {
        this.password = this.previousPassowrd;
      } else if (text !== this.previousPassowrd) {
        this.$emit("change", text);
        this.previousPassowrd = text;
      }

      this.$emit("end", text);
    },

    togglePassword() {
      this.showPassword = !this.showPassword;
      this.focus();
    },

    hoverOnEye(isHoverOnEye: boolean) {
      this.isHoverOnEye = isHoverOnEye;
    },
  },
});
