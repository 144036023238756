import Vue, { VNode } from "vue";

import "./main.scss";

import App from "./App.vue";
import router from "./router";
import store from "./store";

import "fomantic-ui-css/semantic.js";

Vue.config.productionTip = false;

window.addEventListener(
  "load",
  () => {
    new Vue({
      router,
      store,
      render: (h): VNode => h(App),
    }).$mount("#app");
  },
  { once: true }
);
