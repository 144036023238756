





















import Vue from "vue";
import { Portal } from "portal-vue";

import state from "~/state";

export default Vue.extend({
  components: { Portal },

  props: {
    size: {
      type: String,
      default: "",
    },

    grow: {
      type: Boolean,
      default: false,
    },

    autofocus: {
      type: Boolean,
      default: true,
    },

    closable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      show: false,
      focusing: false,
    };
  },

  computed: {
    to() {
      return state.portal;
    },
  },

  methods: {
    open(): void {
      this.show = true;
    },

    close(): void {
      if (this.closable) {
        this.show = false;
      }
    },

    enter() {
      const modal = this.$refs.modal as HTMLElement;

      if (this.autofocus) {
        this.focusDescendant(modal);
      } else {
        modal.focus();
      }

      document.addEventListener("focus", this.trapFocus, true);
      this.$emit("opened");
    },

    afterLeave(): void {
      document.removeEventListener("focus", this.trapFocus, true);
      this.$emit("closed");
    },

    trapFocus(event: FocusEvent) {
      if (this.focusing) return;

      const modal = this.$refs.modal as Element | undefined;

      if (modal != null && !modal.contains(event.target as Node | null)) {
        this.focusDescendant(modal, event.target !== this.$refs.prev);
      }
    },

    focusDescendant(node: Node, first = true) {
      const nodes = Array.from(node.childNodes);

      for (const node of first ? nodes : nodes.reverse()) {
        if (!(node instanceof HTMLElement)) continue;

        this.focusing = true;
        node.focus();
        this.focusing = false;

        if (document.activeElement === node) {
          return true;
        }

        if (this.focusDescendant(node, first)) {
          return true;
        }
      }

      return false;
    },
  },
});
