import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  projectId: process.env.PROJECT_ID,
  storageBucket: `${process.env.PROJECT_ID}.appspot.com`,
};

const app = firebase.initializeApp(firebaseConfig);

app.firestore().settings({ ignoreUndefinedProperties: true, merge: true });

export const functions = app.functions("asia-northeast1");

if (location.hostname === "localhost") {
  app.auth().useEmulator(`http://${location.hostname}:9099`);
  app.firestore().useEmulator(location.hostname, 8080);
  app.storage().useEmulator(location.hostname, 9199);
  functions.useEmulator(location.hostname, 5001);
}

export default firebase;
