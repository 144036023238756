import AudioResampler from "./audio-resampler";

declare global {
  interface Window {
    AudioContext: typeof AudioContext;
    webkitAudioContext: typeof AudioContext;
  }
}

export default class AudioRecorder {
  private readonly sampleRate = 16000;
  private resampler!: AudioResampler;

  private stream?: MediaStream;
  private processor?: ScriptProcessorNode;
  private listener?: (data: Int16Array) => void;

  async record(listener: (data: Int16Array) => void): Promise<void> {
    this.stop();

    this.listener = listener;

    this.stream = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });

    const AudioContext = window.AudioContext || window.webkitAudioContext;
    const context = new AudioContext({
      sampleRate: this.sampleRate,
    });
    const source = context.createMediaStreamSource(this.stream);
    const bufferSize = context.sampleRate === 16000 ? 2048 : 4096;

    this.resampler = new AudioResampler(context.sampleRate, this.sampleRate);
    this.processor = context.createScriptProcessor(bufferSize, 1, 1);

    source.connect(this.processor);
    this.processor.connect(context.destination);
    this.processor.onaudioprocess = this.onAudioProcess;
  }

  stop(): void {
    if (this.processor) {
      this.processor.disconnect();
      this.processor = undefined;
    }

    if (this.stream) {
      if (this.stream.active) {
        this.stream.getTracks()[0].stop();
      }

      this.stream = undefined;
    }
  }

  private onAudioProcess = (event: AudioProcessingEvent): void => {
    if (!this.listener) {
      return;
    }

    const floatData = event.inputBuffer.getChannelData(0);
    const resampleData = this.resampler.resample(floatData);

    const int16Data = Int16Array.from(resampleData, (value) => {
      if (value < 0) {
        const min = 0x8000;
        return Math.max(value * min, -min);
      } else {
        const max = 0x7fff;
        return Math.min(value * max, max);
      }
    });

    this.listener(int16Data);
  };
}
