




































import Vue, { PropType } from "vue";

import { localeDateTime, durationHMM } from "~/filters/time";
import { RoomDoc } from "~/db";

export default Vue.extend({
  filters: {
    localeDateTime,
    durationHMM,
  },

  props: {
    room: {
      type: Object as PropType<RoomDoc>,
      required: true,
    },
  },

  computed: {
    amount(): number {
      return (
        (this.room.data.endTime?.seconds || 0) -
        (this.room.data.startTime?.seconds || 0) +
        (this.room.data.amount || 0)
      );
    },
  },

  methods: {
    move(): void {
      this.$emit("move", this.room);
    },

    delete() {
      this.$emit("delete", this.room);
    },
  },
});
