














import Vue from "vue";

import ModalDialog from "~/components/ModalDialog.vue";
import { FolderDoc } from "~/db";

export default Vue.extend({
  components: { ModalDialog },

  data() {
    return {
      folder: null as FolderDoc | null,
    };
  },

  computed: {
    dialog(): InstanceType<typeof ModalDialog> {
      return this.$refs.dialog as InstanceType<typeof ModalDialog>;
    },
  },

  methods: {
    open(folder: FolderDoc): void {
      this.folder = folder;
      this.dialog.open();
    },

    close(): void {
      this.dialog.close();
    },

    async delete_(): Promise<void> {
      await this.folder?.ref.delete();
      this.close();
    },

    closed(): void {
      this.folder = null;
    },
  },
});
