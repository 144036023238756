import firebase from "~/utils/firebase";

import { BaseDocument } from "./document";

export interface OrganizationData {
  name: string;
  plan?: number;
  limit: number;
  amount: number;
  contact?: string;

  createTime: firebase.firestore.Timestamp;
  updateTime: firebase.firestore.Timestamp;
}

export const dataConverter: firebase.firestore.FirestoreDataConverter<OrganizationData> =
  {
    toFirestore(organization: Partial<OrganizationData>) {
      const { name, plan, limit, amount, contact, createTime, updateTime } =
        organization;
      return { name, plan, limit, amount, contact, createTime, updateTime };
    },

    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot,
      options?: firebase.firestore.SnapshotOptions
    ) {
      const data = snapshot.data(options);
      const epoch = new firebase.firestore.Timestamp(0, 0);
      const { name, plan, limit, amount, contact, createTime, updateTime } = {
        ...{
          name: "",
          limit: 0,
          amount: 0,
          contact: "",
          createTime: epoch,
          updateTime: epoch,
        },
        ...(data as Partial<OrganizationData>),
      };

      return { name, plan, limit, amount, contact, createTime, updateTime };
    },
  };

export class OrganizationDoc extends BaseDocument<OrganizationData> {
  static collection(): firebase.firestore.CollectionReference<OrganizationData> {
    return firebase
      .firestore()
      .collection("organizations")
      .withConverter(dataConverter);
  }

  static async addOrganization(name: string): Promise<string> {
    const limit = 10 * 60 * 60; // 10h
    const amount = 0;
    return this.add(this.collection(), { name, limit, amount });
  }

  static async getOrganization(
    oid: string
  ): Promise<OrganizationDoc | undefined> {
    return this.getDocument(this.collection().doc(oid));
  }

  static async listenOrganization(
    oid: string
  ): Promise<OrganizationDoc | undefined> {
    const ref = this.collection().doc(oid);

    return this.listenDocument(ref);
  }

  static listenOrganizations(): OrganizationDoc[] {
    return this.listenDocuments(this.collection());
  }
}
