




































































































































































import Vue, { PropType } from "vue";
import { mapState, mapGetters } from "vuex";

import { PortalTarget } from "portal-vue";
import mixins from "vue-typed-mixins";

import Checkbox from "~/components/Checkbox.vue";
import PhraseCreate from "~/components/PhraseCreate.vue";
import Radio from "~/components/Radio.vue";
import RoomConsole from "~/components/RoomConsole.vue";
import RoomSharing from "~/components/RoomSharing.vue";
import SpeechChart from "~/components/SpeechChart.vue";
import SpeechList from "~/components/SpeechList.vue";
import TextField from "~/components/TextField.vue";
import * as db from "~/db";
import { RoomDoc, SpeechDoc } from "~/db";
import Event from "~/mixin/event";
import state from "~/state";
import $ from "~/utils/jquery-loader";
import Subtitles from "~/utils/subtitles";
import Word from "~/utils/word";
import RecodingExportModal from "~/components/RecodingExportModal.vue";

export default mixins(Event).extend({
  components: {
    Checkbox,
    PhraseCreate,
    PortalTarget,
    Radio,
    RecodingExportModal,
    RoomConsole,
    RoomSharing,
    SpeechChart,
    SpeechList,
    TextField,
  },

  props: {
    room: {
      type: Object as PropType<RoomDoc>,
      required: true,
    },
  },

  data() {
    return new (class {
      speeches: SpeechDoc[] = [];
      profiles: { [uid: string]: Profile | undefined } = {};
      showChart = false;
      showConsole = true;
      showSpeaker = true;
      fontSize = "1";
      darkMode = false;
      fullscreen = false;
      autoScroll = true;
      unread = false;
      scrollTop = 0;
      scrollDown = false;
    })();
  },

  computed: {
    live(): boolean {
      return this.room.data.type !== "file";
    },

    shared(): boolean {
      return this.room.data.visibility === "public";
    },

    userLanguage(): string {
      return this.languageCode.split("-")[0];
    },

    lines(): SpeechDoc[][] {
      const lines: SpeechDoc[][] = [];

      this.speeches.reduce<[number, string]>(
        ([length, uid], speech) => {
          if (
            speech.data.transcript.length > this.minLineLength ||
            length + speech.data.transcript.length > this.maxLineLength ||
            uid !== speech.data.uid ||
            speech.language != this.userLanguage
          ) {
            lines.push([]);
            length = 0;
          }

          lines[lines.length - 1].push(speech);

          if (speech.data.transcript.length > this.minLineLength) {
            length = Number.MAX_VALUE;
          } else {
            length += speech.data.transcript.length;
          }

          return [length, speech.data.uid];
        },
        [Number.MAX_VALUE, ""]
      );

      return lines;
    },

    ...mapState("user", ["languageCode", "minLineLength", "maxLineLength"]),
    ...mapGetters("user", ["anonymous"]),
  },

  async created() {
    this.speeches = SpeechDoc.listenSpeeches(this.room.id, async (speech) => {
      if (this.autoScroll) {
        this.$nextTick().then(() => {
          this.scroll();
        });
      } else {
        this.unread = true;
      }

      const uid = speech.data.uid;

      if (uid == null || uid in this.profiles) return;

      const index = Object.keys(this.profiles).length;

      this.$set(this.profiles, uid, undefined);

      const profile = await db.getProfile(uid);

      if (profile != null) {
        profile.index = index;
      }

      this.profiles[uid] = profile;
    });

    this.$once("hook:destroy", () => {
      SpeechDoc.unsubscribe(this.speeches);
    });
  },

  mounted() {
    this.$addEventListener(window, "scroll", () => {
      this.autoScroll =
        window.pageYOffset + window.innerHeight + 1 >=
        document.documentElement.scrollHeight;
      this.unread = this.unread && !this.autoScroll;
    });

    this.$addEventListener(this.$el, "scroll", (event: Event) => {
      const element = event.target as HTMLElement;
      const scrollTop = element.scrollTop;

      this.scrollDown = this.scrollTop < scrollTop && 63 < scrollTop;
      this.scrollTop = scrollTop;

      this.autoScroll =
        element.scrollTop + element.offsetHeight + 1 >= element.scrollHeight;
      this.unread = this.unread && !this.autoScroll;
    });

    this.$addEventListener(document, "fullscreenchange", () => {
      state.portal = document.fullscreenElement ? "room" : "";
    });

    if (this.$route.hash === "#new") {
      const field = (this.$refs.name as Vue).$el as HTMLElement;
      const range = document.createRange();
      const selection = getSelection();

      field.focus();
      range.selectNodeContents(field);
      selection?.removeAllRanges();
      selection?.addRange(range);

      const route = this.$router.currentRoute;
      const resolved = this.$router.resolve({
        name: route.name || "",
        params: route.params,
      });

      this.$router.replace(resolved.location);
    }
  },

  methods: {
    onNameChange(name: string) {
      this.room.update({ name });
    },

    share(enable: boolean) {
      this.room.update({ visibility: enable ? "public" : db.del });
    },

    openShareing(): void {
      if (!this.shared) {
        this.share(true);
      }

      const sharing = this.$refs.sharing as InstanceType<typeof RoomSharing>;
      sharing.open();
    },

    toggleChart() {
      this.showChart = !this.showChart;
    },

    createPhrase() {
      (this.$refs.phrase as InstanceType<typeof PhraseCreate>).open2();
    },

    exportRecoding() {
      (this.$refs.export as InstanceType<typeof RecodingExportModal>).open();
    },

    exportToWord(showName = true, onlyMarkedRow = false) {
      const word = new Word(this.room.data.name);
      for (const line of this.lines) {
        const speech = line[0];

        if (onlyMarkedRow && !speech.data.mark) {
          continue;
        }

        const profile = this.profiles[speech.data.uid];
        const name = showName ? profile?.name || "" : undefined;

        const text = line.reduce((transcript, speech) => {
          return transcript + speech.data.transcript;
        }, "");

        word.add(name, text);
      }

      const needIndent = showName ? true : false;

      word.save(needIndent);
    },

    exportToSubRip() {
      const subtitles = new Subtitles(this.room.data.createTime.toDate());

      for (const speech of this.speeches) {
        subtitles.add(
          speech.data.createTime.toDate(),
          speech.data.duration,
          speech.data.transcript
        );
      }

      subtitles.saveToSubRip(this.room.data.name);
    },

    exportToSubViewer() {
      const subtitles = new Subtitles(this.room.data.createTime.toDate());

      for (const speech of this.speeches) {
        subtitles.add(
          speech.data.createTime.toDate(),
          speech.data.duration,
          speech.data.transcript
        );
      }

      subtitles.saveToSubViewer(this.room.data.name);
    },

    async requestFullscreen(active: boolean): Promise<void> {
      if (active) {
        await this.$el.requestFullscreen();
      } else if (document.fullscreenElement != null) {
        await document.exitFullscreen();
      } else {
        this.fullscreen = false;
      }
    },

    handleFullscreenChange(event: Event): void {
      this.fullscreen = document.fullscreenElement === event.target;
    },

    scroll() {
      (this.$refs.bottom as Element).scrollIntoView();
    },

    show(speech: SpeechDoc) {
      const id = `speech-${speech.id}`;
      const element = document.getElementById(id);

      if (!element) return;

      window.scrollTo({ top: element.offsetTop, behavior: "smooth" });
      $(element).transition("glow");
    },
  },
});
