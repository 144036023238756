





























import Vue from "vue";

import Modal from "~/components/Modal.vue";

import { OrganizationDoc } from "~/db";

export default Vue.extend({
  components: { Modal },

  data() {
    return {
      name: "",
      loading: false,
      error: undefined as Error | undefined,
    };
  },

  computed: {
    formClass(): unknown {
      return {
        loading: this.loading,
        error: this.error != null,
      };
    },
  },

  methods: {
    modal() {
      return this.$refs.modal as InstanceType<typeof Modal>;
    },

    open() {
      this.modal().open();
    },

    close() {
      this.modal().close();
    },

    async create() {
      if (this.name.length === 0) return;

      this.loading = true;

      await OrganizationDoc.addOrganization(this.name);

      this.loading = false;
      this.close();
    },

    closed() {
      this.name = "";
      this.loading = false;
      this.error = undefined;
    },
  },
});
