






















































































































































































































































































































































































































































































































































import Vue from "vue";

import GuideFigure from "~/components/GuideFigure.vue";

export default Vue.extend({
  components: {
    GuideFigure,
  },

  methods: {
    figure(event: Event): void {
      if (event.target instanceof Element) {
        const src = event.target.getAttribute("src");

        if (src) {
          (this.$refs.figure as InstanceType<typeof GuideFigure>).open(src);
        }
      }
    },
  },
});
