


























import Vue from "vue";

import Modal from "~/components/Modal.vue";

import * as db from "~/db";

export default Vue.extend({
  components: { Modal },

  data() {
    return {
      name: "",
      loading: false,
      error: undefined as Error | undefined,
    };
  },

  computed: {
    formClass(): unknown {
      return {
        loading: this.loading,
        error: this.error != null,
      };
    },
  },

  methods: {
    modal(): InstanceType<typeof Modal> {
      return this.$refs.modal as InstanceType<typeof Modal>;
    },

    open() {
      this.modal().open();
    },

    close() {
      this.modal().close();
    },

    create() {
      if (this.name.length === 0) return;

      this.loading = true;

      db.addGroup(this.name)
        .then(() => {
          this.close();
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    closed() {
      this.name = "";
      this.loading = false;
      this.error = undefined;
    },
  },
});
