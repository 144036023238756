






























































import Vue from "vue";
import { mapGetters } from "vuex";

import { UsageDoc, OrganizationDoc, UserDoc } from "~/db";
import { durationH } from "~/filters/time";
import Sortable from "~/utils/sortable";
import { createPdf } from "~/utils/usages";

import TableHeader from "~/components/TableHeader.vue";
import UsageRow from "~/components/UsageRow.vue";

type SortType = "useDate" | "organizationName" | "plan" | "amount";

export default Vue.extend({
  name: "Usages",

  components: {
    TableHeader,
    UsageRow,
  },

  data() {
    return {
      usages: [] as UsageDoc[],
      organizations: [] as OrganizationDoc[],
      organization: undefined as OrganizationDoc | undefined,
      sortable: new Sortable<SortType>("useDate", -1),
    };
  },

  computed: {
    sortedUsages(): UsageDoc[] {
      return (
        this.usages
          .slice()
          // トライアルを除外する
          .filter((usage) => {
            return usage.id !== "trial";
          })
          .sort((a, b) => {
            let result = 0;

            switch (this.sortable.type) {
              case "useDate":
                result = a.id.localeCompare(b.id);
                break;
              case "organizationName": {
                const _a = this.getOrganization(a);
                const _b = this.getOrganization(b);
                const aName = _a === undefined ? "" : _a.data.name;
                const bName = _b === undefined ? "" : _b.data.name;

                result = aName.localeCompare(bName);
                break;
              }
              case "amount":
                result = a.data.amount - b.data.amount;
                break;
              case "plan": {
                const aPlan = a.data.limit;
                const bPlan = b.data.limit;

                result = aPlan - bPlan;
                break;
              }
            }

            return result * this.sortable.order;
          })
      );
    },

    plan(): string {
      if (this.organization?.data.plan === undefined) {
        return "トライアル";
      }

      const plan = this.organization.data.plan;

      if (plan === 0) {
        return "未契約";
      }

      return `${durationH(plan)}時間/月`;
    },

    subscribed(): boolean {
      if (this.admin) {
        return true;
      }

      return (this.organization?.data.plan ?? 0) > 0;
    },

    ...mapGetters("user", ["admin", "oid", "owner"]),
  },

  async created() {
    if (this.admin) {
      this.usages = UsageDoc.listenUsages();
      this.organizations = OrganizationDoc.listenOrganizations();
    } else {
      if (!this.owner || this.oid === undefined) {
        this.$router.push({ name: "root" });
      }

      this.usages = UsageDoc.listenUsages(this.oid);
      this.organization = await OrganizationDoc.listenOrganization(this.oid);
    }

    this.$once("hook:destroy", () => {
      UsageDoc.unsubscribe(this.usages);
      OrganizationDoc.unsubscribe(this.organizations);
      OrganizationDoc.unsubscribe(this.organization);
    });
  },

  methods: {
    getKey(usage: UserDoc): string {
      return `${usage.ref.parent.parent?.id}-${usage.ref.id}`;
    },

    getOrganization(usage: UsageDoc): OrganizationDoc | undefined {
      return this.organizations.find((organization) => {
        return organization.id === usage.ref.parent.parent?.id;
      });
    },

    createPdf(): void {
      createPdf(this.organization, this.sortedUsages);
    },
  },
});
